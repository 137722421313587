import axios from 'axios';
import React from 'react';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import Slider from 'react-slick';
import { UserContext } from '../UserContext/UserContext';
import { Link } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';

export default function SliderHome() {


  

  let { BasUrl3, en } = useContext(UserContext);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function SliderHomee() {
    return axios.get(`https://dashboard.cmask.net/front-api/home`);
  }

  let { isError, data, isLoading } = useQuery('catogrySlider', SliderHomee);



  return (
    <>
      <div className='slider-cartCatogry d-block'>
        {isLoading ? (
          <div className='d-flex justify-content-center align-items-center' style={{ height: '80vh' }}>
            <BallTriangle height={100} width={100} radius={5} color='#4fa94d' ariaLabel='ball-triangle-loading' visible={true} />
          </div>
        ) : isError ? (
          <div>Error fetching data</div>
        ) : (
          <Slider {...settings} className=''>
            {data?.data?.data?.slider?.map((category) => (
              <Link to={`/ProductDitales/${category.id}`}>
                <img key={category.id} src={category?.image_name} height={500} className='w-100' />
              </Link>
            ))}
          </Slider>
        )}
        {data?.data?.data?.button_text && (
          <button className='your-button-class'>{data?.data?.data?.button_text}</button>
        )}
      </div>
    </>
  );
}
