// i18next.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translations.json';
import translationAR from './locales/ar/translations.json';

let currentLanguage = localStorage.getItem('language') || 'en';

const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: currentLanguage,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export function changeLanguage(newLanguage) {
  if (resources[newLanguage]) {
    currentLanguage = newLanguage;
    localStorage.setItem('language', newLanguage);
    i18n.changeLanguage(newLanguage);
  }
}

export default i18n;
