import React from 'react';
import styles from './Home.module.css';
import FudcharProduct from '../FudcharProduct/FudcharProduct';
import CatogrySlider from '../CatogrySlider/CatogrySlider';
import SliderCatogry from '../CatogrySlider/SliderCatogry';
import ProductSlider from '../CatogrySlider/ProductSlider';
import Ads from '../Ads/Ads';
import OfferSlider from '../Offers/OffersSlider';
import Proposals from '../Proposals/Proposals';
import SearchAPI from '../Search/Search';
import CatogrySlideer from '../CatogrySlider/CatogrySlider';
import SliderHome from '../CatogrySlider/SliderHome';

export default function Home() {
  return <>
  
  <SliderHome />
  <SliderCatogry className="mt-5"/>
  <ProductSlider className="mt-5"/>
  <Ads className='mt-5'/>
  <OfferSlider className="mt-5"/>
  <Proposals className="mt-5"/>
 
  </>
}
