import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { BallTriangle } from 'react-loader-spinner';
import NameCatogry from './NameCatogry';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { UserContext } from '../UserContext/UserContext';
import SubSubCategory from './SubSubCatogry';
import InputSearch from '../Search/InputSearch';

export default function Category() {
  // <SubSubCategory propsx={"01126303082"} />

  let { BasUrl3 , en} = useContext(UserContext)

  const [value, setValue] = useState(5);
  const [value2, setValue2] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [subCategoryValue, setSubCategoryValue] = useState('Ahmedd011125588 ');


  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setSelectedId(id);
    }
  }, [id]);
  function getCatogry(id) {
    return axios.get(`${BasUrl3}productsCategory/${id}${en}`);
  }

  const { isError, data, isLoading } = useQuery(['catogrySlider', selectedId], () => getCatogry(selectedId));
  
  console.log(data?.data?.data[0]?.products, 'catogryyyyyyyyyyyyyyyyyy');
  


  
  useEffect(() => {
    async function futch() {
      if (data?.data?.data[0]?.products) {
        setValue(data?.data?.data[0]?.products?.rate);
        setValue2(5 - data?.data?.data[0]?.products?.rate);
      }
    }
    futch();
  }, [data]);

  return (
    <div className='container mt-5'>
    {/* <InputSearch/> */}
      <div className='row'>
        <div className='col-sm-9 '>
          <div className='row'>
          <SubSubCategory  pramuesId={id}/>
            {isLoading ? (
              <div className='col-sm-9'>
                <BallTriangle
                  height={100}
                  width={100}
                  radius={5}
                  color="#4fa94d"
                  ariaLabel="ball-triangle-loading"
                />
              </div>
            ) : !data?.data?.data || !Array.isArray(data?.data?.data[0]?.products) ? (
              <h1 className='text-danger'>No data available</h1>
            ) : (
              data?.data?.data[0].products?.map((imgAds) => (
                <div className='col-lg-3 col-md-12' key={imgAds.id}>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <Link to={`/ProductDitales/${imgAds.id}`}>
                          <div className="product shadow post-slide pt-0 mt-0 w-100" key={imgAds.id}>
                            <div className="product-img post-img text-center position-relative">
                            <div className="position-relative overflow-hidden">
                  <img
                      src={imgAds.image}
                      alt=""
                      className="     "
                      height={200}
                    />
               <div className='position-absolute   sliederHover'></div>

                  </div>
                  <div className="d-flex justify-content-between">
                      <div className="mx-3">
                        {Array(parseInt(imgAds?.rate))
                          .fill(0)
                          .map((_, i) => (
                            <FontAwesomeIcon
                              icon={faStar}
                              key={i}
                              style={{ fontSize: "15px" }}
                            />
                          ))}
                        {Array(5 - parseInt(imgAds?.rate))
                          .fill(0)
                          .map((_, i) => (
                            <FontAwesomeIcon
                              icon={faStar}
                              key={i}
                              style={{ color: "#f2f0ee" }}
                            />
                          ))}
                      </div>
                    </div>
                              <h5 className="fw-bold text-end px-4"> {imgAds.price} SAR</h5>
                              <p>{imgAds.name.split(" ").slice(0, 5).join('  ')}</p>
                            </div>
                            <div className="d-flex over-product text-start px-3 mt-3 font-9 ">
                {imgAds.available == 1 ? <>
                 
                </> : <>
                <div className=" w-100">
                {/* <p className="text-green px-2 cursor-pointer bg-danger "> {t("unavailable")}</p> */}
                  <span className="mt-2 "></span>
                </div>
                </>}

              </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className='col-sm-3'>
          <NameCatogry />
        </div>
      </div>
    </div>
  );
}
