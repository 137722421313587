import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../UserContext/UserContext';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function Cities() {

    let { setUserToken, UserToken, BasUrl, BasUrl3, counterCart , en } = useContext(UserContext)
    const token = localStorage.getItem('UserToken');

        
    const [settings, setSettings] = useState(null);
    const [loading, setLoading] = useState(true);
 const [params, setparams] = useState(0)

 let Params = useParams()
//  setparams(params)
 console.log(Params.id);
 useEffect(() => {
    async function fetchSettings(pro) {
        try {
            const response = await axios.get(`https://dashboard.cmask.net/api/cities/${Params.id}${en}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSettings(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }

    fetchSettings(Params.id);
}, [Params.id, en, token]); 

      console.log(settings?.data);
      

  return <>  
    <div className='container'>
        <div className='row'>
        {
    settings?.data.map((Cit)=><>
            <div className='col-3 mt-3'>
                <div className='bg-light'>
                    <div className='pt-3 pb-3 '>
                        <h5 className='text-center fw-bold'>{Cit.name}</h5>
                    </div>

                </div>
            </div>


            </>)

    
}
        </div>
    </div>
 
    
  </>
}
