import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { UserContext } from '../Components/UserContext/UserContext';
import DeleteNotifications from './DeleteNotifications';
import DeleteAll from './DeteAll';
import { BallTriangle } from 'react-loader-spinner';

export default function Notifications() {
  const { BasUrl3, en } = useContext(UserContext);
  const [token, setToken] = useState(localStorage.getItem('UserToken'));

  useEffect(() => {
    // يتم تحديث قيمة التوكن عندما يتغير في localStorage
    setToken(localStorage.getItem('UserToken'));
  }, [localStorage.getItem('UserToken')]);

  function getNotifications() {
    return axios.get(`${BasUrl3}notifications${en}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  const { isError, data, isLoading } = useQuery('notifications', getNotifications);

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12">
          <div className='d-flex justify-content-between mt-4'>
            <div className='mx-3'><h2>الإشعارات</h2></div>
            <div className='d-flex mx-3  cusor pointer-event'>
              <DeleteAll/>
              {/* <p><i className="fa fa-trash text-danger font-15" aria-hidden="true"></i></p>
              <p className='mx-2 text-danger font-15'>Delete all</p> */}
            </div>
          </div>
        </div>
  
        {/* -------------------------------- */}
        <div className="col-12 mt-4">
          <div className='d-flex justify-content-between '>
            {isLoading ? (
           <div className='d-flex justify-content-center align-items-center '>
           <BallTriangle
               height={100}
               width={100}
               radius={5}
               color="#4fa94d"
               ariaLabel="ball-triangle-loading"
               wrapperClass={{}}
               wrapperStyle=""
               visible={true}
           />
       </div>
            ) : isError ? (
              <p>Error fetching notifications</p>
            ) : (
              data?.data?.data.map((notification, index) => (
                <div key={index} className='d-flex mx-4 justify-content-between w-100'>
                <div className='d-flex'>
                <p className='min-color'><i className="fa-solid fa-bell  font-14 mt-2 pt-1 min-color"></i></p>
                  <p className='mx-5 my-2  '>{notification.data.body}</p>
                </div>
               <div>
               <p className="mt-2 mx-4">    <DeleteNotifications id={notification.id}/>    </p>
               </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
  
}
