import React, { useContext, useEffect } from 'react';
import styles from './Layout.module.css';
import { Outlet } from 'react-router-dom';
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer';
import { UserContext } from '../UserContext/UserContext';
export default function Layout() {

  let {setUserToken} = useContext(UserContext)

  useEffect(()=>{
    if(localStorage.getItem("UserToken" ) !== null){
      setUserToken(localStorage.getItem("UserToken" ) )
    }
  } , [])

  return <>
  <Navbar/>

  <Outlet></Outlet>
  
  <Footer/>
  </>
}
