import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import Pending from './pending';
import Shipping from './shipping';
import Received from './Received';
import { UserContext } from '../UserContext/UserContext';
import { useTranslation } from 'react-i18next';

export default function ShowOrder() {
    const [orderData, setOrderData] = useState([]);
    let { BasUrl3 , en} = useContext(UserContext)
    const { t } = useTranslation();

    useEffect(() => {
        async function fetchOrderData() {
            try {
                const token = localStorage.getItem('UserToken');
                const response = await axios.get(`https://dashboard.cmask.net/api/order${en}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setOrderData(response.data?.data || []); // تعيين قائمة الطلبات إلى الحالة الداخلية
            } catch (error) {
                console.error('حدث خطأ:', error);
            }
        }
        fetchOrderData();
    }, []);

    return (
        <div className='container '>
            {orderData.length > 0 ? (
                <>
                    <div className='row'>
                        <div className='col-12'>
                            <ul className="nav nav-pills d-flex justify-content-between mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">pending</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">shipping</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">received</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-contact-tabb" data-bs-toggle="pill" data-bs-target="#pills-contactt" type="button" role="tab" aria-controls="pills-contactt" aria-selected="false">الكل</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"><Pending/></div>
                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"><Shipping/> </div>
                                <div className="tab-pane fade" id="pills-contactt" role="tabpanel" aria-labelledby="pills-contactt-tabb"> 
                                    <div className='row'>
                                        <div className='row'>
                                            {orderData.map((order) => (
                                                <div className='col-lg-4 col-md-6'>
                                                <div className='' key={order.id}>
                                                    <div className='col-12'>
                                                        <div className='container'>
                                                            <div className=''>
                                                                <div className='boxshow p-4 '>
                                                                    <p>{t('adresse')}:  <span className='text-dark fw-bold mx-2'>{order.address} </span></p>
                                                                    <p>{t('city')}: <span className='text-dark fw-bold mx-2'>{order.city} </span></p>
                                                                    <p>{t('delete')}: <span className='text-dark fw-bold mx-2'>{order.order_date}</span></p>
                                                                    <p>{t('otalmount')}: <span className='text-dark fw-bold mx-2'>{order.total_amount}</span></p>
                                                                    <p>{t('total')}  : <span className='text-dark fw-bold mx-2'>{order.total_price}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab"> <Received/> </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className='d-flex justify-content-center align-items-center' style={{ height: '80vh' }}>
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#4fa94d"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    />
                </div>
            )}
        </div>
    );
}
