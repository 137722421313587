import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext/UserContext';
import { useContext } from 'react';
import { toast } from 'react-toastify';

export default function ResetPassword() {
  let { BasUrl3 , en} = useContext(UserContext)
  let Navigate = useNavigate();
  const [error, seterror] = useState(null);
  const [isLoading, setisLoding] = useState(false);

  async function onSubmit(values) {
    try {
      setisLoding(true);
      const { data } = await axios.put(`${BasUrl3}password/reset-password${en}`, values);

      console.log(data);
  
      if (data.status === true) {
        setisLoding(false);
        toast.success(data?.data?.message, {
          position: 'top-center'
        })
        Navigate("/Login");
      }
    } catch (error) {
      setisLoding(false);
      if (error.response) {
        console.error("Server Error:", error.response.data);
        seterror(error.response.data.message);
      } else {
        console.error("Error:", error.message);
        seterror("An error occurred");
      }
    }
  }
  const validationSchema = yup.object({
    email: yup.string().email().required("Email is required"),
    password: yup.string().matches(/^[A-Z][a-z0-9]{5,10}/, "Password is not valid").required("Password is required"),
    password_confirmation: yup.string().oneOf([yup.ref("password")], "Passwords must match").required("Password confirmation is required"),
    otp: yup.string().min(6, "OTP must be 6 characters").max(6, "OTP must be 6 characters").required("OTP is required"),
  });
  let formik = useFormik({
    initialValues: {
      email: "",
      otp: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <div className='w-50 mx-auto py-5'>
      {error && <div className='alert alert-danger'>{error}</div>}

      <form onSubmit={formik.handleSubmit}>
        <h5 className='fw-bold text-center'>تسجيل الدخول لحسابي </h5>

        <input name="_method" type="hidden" value="put" />

        <label htmlFor='email' className='mt-2'>البريد الاكتروني:</label>
        <input type='email' className='form-control mt-2' id="email" onBlur={formik.handleBlur} name='email' value={formik.values.email} onChange={formik.handleChange} />
        {formik.errors.email && formik.touched.email && <div className='alert alert-danger'>{formik.errors.email}</div>}

        <label htmlFor='otp' className='mt-2'>OTP:</label>
        <input type='number' className='form-control mt-2' id="otp" onBlur={formik.handleBlur} name='otp' value={formik.values.otp} onChange={formik.handleChange} />
        {formik.errors.otp && formik.touched.otp && <div className='alert alert-danger'>{formik.errors.otp}</div>}


        <label htmlFor='password' className='mt-2'>كلمة السر الجديده:</label>
        <input type='password' className='form-control mt-2' id="password" onBlur={formik.handleBlur} name='password' value={formik.values.password} onChange={formik.handleChange} />
        {formik.errors.password && formik.touched.password && <div className='alert alert-danger'>{formik.errors.password}</div>}

        <label htmlFor='password_confirmation' className='mt-2'>اعادة كتابة كلمة السر :</label>
        <input type='password' className='form-control mt-2' id="password_confirmation" onBlur={formik.handleBlur} name='password_confirmation' value={formik.values.password_confirmation} onChange={formik.handleChange} />
        {formik.errors.password_confirmation && formik.touched.password_confirmation && <div className='alert alert-danger'>{formik.errors.password_confirmation}</div>}

        
        {isLoading ? (
          <button className='btn bg-mainbtn-add-card mt-2' type='button'>
            <button className='btn bg-color btn-add-card '>  <i className='fas fa-spinner fa-spin'></i></button>
          </button>
        ) : (
          <button disabled={!(formik.isValid && formik.dirty)} className='btn btn-add-card w-100 border-radius p-8' type='submit'>Reset Password</button>
        )}
      </form>
    </div>
  );
}
