import React, { useContext, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { UserContext } from '../Components/UserContext/UserContext';

const DelateFaforrit = ({ ptoductId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  let { BasUrl3 , en} = useContext(UserContext)

  const handleDeleteFromFavorites = async () => {
    setLoading(true);

    try {
      const token = localStorage.getItem('UserToken');
      if (!token) {
        throw new Error('User is not authenticated');
      }

      const response = await axios.delete(
        `${BasUrl3}favourites/${ptoductId}${en}`, // استبدل هذا برابط API الفعلي الخاص بك
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.status === true) {
        toast.success(response?.data?.message, {
          position: 'top-center'
        });
      }

      setLoading(false);
      console.log('Item deleted from favorites:', response?.data);
      // قم بتنفيذ منطق رسالة النجاح أو تحديث الحالة عند الحذف بنجاح
    } catch (error) {
      setLoading(false);
      setError('Failed to delete item from favorites');
      console.error('Error deleting item from favorites:', error);
    }
  };

  return (
    <div>
      <button onClick={handleDeleteFromFavorites} disabled={loading} className='btn border btn-danger'>
        {loading ? <i class="fa-solid fa-x"></i> : <i class="fa-solid fa-x"></i>}
      </button>
      {error && <div>{error}</div>}
    </div>
  );
};

export default DelateFaforrit;
