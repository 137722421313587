import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../UserContext/UserContext';
import axios from 'axios';
import { useQuery } from 'react-query';

export default function Guide_file() {

    let { setUserToken, UserToken, BasUrl, BasUrl3, counterCart , en } = useContext(UserContext)

    
    function getFeaturProductt() {
      return axios.post(`${BasUrl3}home${en}`)
  
    }
    let { data, isError, isLoading } = useQuery("feadurProducts", getFeaturProductt)
  
    const [settings, setSettings] = useState(null);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      async function fetchSettings() {
        try {
          const response = await axios.get(`https://dashboard.cmask.net/api/settings${en}`);
          setSettings(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      }
  
      fetchSettings();
    }, []);
    console.log(settings?.data[0] , 'settingssettingssettingssettingssettings');

  return (
    <div className='mt-5 container'>{settings?.data[0].guide_file}    accordion
    
    
    
    <video width="750" height="500" controls >
      <source src="https://cmask.net/dashboard/public/Attachment/Settings/VID-20230807-WA0001%20(1).mp4" type="video/mp4"/>
     </video>
    
    </div>
  )
}
