import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../UserContext/UserContext';

export default function DeleteReview({ idProduct }) {
  const [reviews, setReviews] = useState([]);
  const token = localStorage.getItem('UserToken');
  let { BasUrl3 , en} = useContext(UserContext)

  // Function to fetch reviews
  const fetchReviews = async () => {
    try {
      const response = await axios.get(`${BasUrl3}product-review/${idProduct}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setReviews(response.data.data || []);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };

  useEffect(() => {
    fetchReviews(); // Fetch reviews when the component mounts
  }, [idProduct]);

  // Function to delete a review
  const deleteReview = async (userToken) => {
    try {
      await axios.delete(`${BasUrl3}product-review/destroy/${idProduct}${en}`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      console.log('Review deleted successfully');
      fetchReviews(); // Fetch reviews after deletion to update UI
    } catch (error) {
      console.error('Error deleting review:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteReview(token);
    } catch (error) {
      console.error('Error deleting review:', error);
    }
  };

  return (
    <div className='text-center pb-2 mx-4 pt-0 my-0'>
      <button onClick={handleDelete} className='btn bg-danger text-white btn-sm'> <i class="fa-solid fa-x"></i></button>

    </div>
  );
}
