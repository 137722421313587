import React, { useContext, useState } from 'react';
import axios from 'axios';
import { FaStar } from 'react-icons/fa'; // استيراد الرمز من حزمة react-icons
import { UserContext } from '../UserContext/UserContext';
import { useTranslation } from 'react-i18next';

// const productId = 8;

const Ratings = ( {propsId} ) => {
    // console.log({propsId})  , 'propsosososooprops';
  const [customer_name, setcustomer_name] = useState('');
  const [comment, setcomment] = useState('');
  const [rate, setrate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  let { BasUrl3 , en} = useContext(UserContext)

  const { t } = useTranslation();

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('UserToken');
      if (!token) {
        throw new Error('User is not authenticated');
      }

      const response = await axios.post(
        `${BasUrl3}product-review/store/${propsId}${en}`,
        {
          customer_name: customer_name,
          comment,
          rate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setLoading(false);
      console.log('Review submitted:', response.data);
      // إعادة توجيه المستخدم بعد تقديم التقييم إلى الصفحة المناسبة
      // replace('/product-details') أو أي صفحة أخرى
    } catch (error) {
      setLoading(false);
      setError('Failed to submit review');
      console.error('Error submitting review:', error);
    }
  };

  // توليد نجوم لتقييم المنتج
  const starRating = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <label key={i}>
          <input
            type="radio"
            name="rating"
            value={i}
            onClick={() => setrate(i)}
          />
          <FaStar
            size={30}
            color={i <= rating ? '#ffc107' : '#e4e5e9'}
            style={{
              marginRight: 10,
              cursor: 'pointer',
            }}
          />
        </label>
      );
    }
    return stars;
  };

  return (
    <div className="col-12">
      <h3>{t('addeview')}</h3>
      {error && <div>Error: {error}</div>}
      <form onSubmit={handleReviewSubmit}>
        <div className="">
          <label className='mt-2'>Customer Name:</label>
          <input
            type="text"
            className="form-control w-100 mt-2"
            value={customer_name}
            onChange={(e) => setcustomer_name(e.target.value)}
          />
        </div>
        <div>
          <label>comment:</label>
          <textarea
            value={comment}
            onChange={(e) => setcomment(e.target.value)}
            className="form-control -100 mt-2"
          ></textarea>
        </div>
        <div>
          <label>rate:</label>
          <div style={{ display: 'flex' }} className='mt-2'>{starRating(rate)}</div>
        </div>
        <button type="submit" className='mt-2 btn-add-card btn' disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default Ratings;
