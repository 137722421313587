import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import AuctionPost from "./AuctionPost";
import { Button, Collapse } from "react-bootstrap";
import { UserContext } from "../UserContext/UserContext";
import { BallTriangle } from "react-loader-spinner";

const Auction = () => {
  const { BasUrl3, en } = useContext(UserContext);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);
  const [open, setOpen] = useState({}); // حالة محلية لتتبع ما إذا كان كل عنصر مفتوحًا أم لا

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BasUrl3}actions${en}`);
        setData(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [BasUrl3, en]);

  useEffect(() => {
    async function futch() {
      if (data?.data.product) {
        data?.data?.product?.map((x) => {
          const rate = parseInt(x?.rate);
          if (!isNaN(rate)) {
            setValue(parseInt(rate));
            setValue2(rate ? 5 - rate : 0);
          }
        });
      }
    }

    futch();
  }, [data]);

  useEffect(() => {
    const calculateTimeRemaining = () => {
      if (data?.data?.length > 0) {
        const updatedData = data.data.map((product) => {
          const endDate = new Date(
            product?.end_date_y,
            product?.end_date_m - 1,
            product?.end_date_d
          );

          const now = new Date();

          const timeDiff = endDate - now;

          const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

          return {
            ...product,
            timeRemaining: {
              days,
              hours,
              minutes,
              seconds,
            },
          };
        });

        setData({ ...data, data: updatedData });
      }
    };

    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [data]);

  const toggleOpen = (productId) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [productId]: !prevOpen[productId], // تحديث الحالة للعنصر المحدد فقط
    }));
  };

  return (
    <>
      {data?.data && data?.data?.length > 0 ? (
        <div className="container mt-5 ">
          <div className="row">
            {data.data.map((product) => (
              <div className="col-3 product  post-slide boxshow" key={product.id}>
                <Link to={`/ProductDitales/${product.id}`} className="col-4">
                  <div className=" pt-0 mt-0">
                    <div className="product-img post-img text-center position-relative">
                      <img src={product.product.image} alt="" className="" height={200} />
                      <div className="d-flex justify-content-between">
                        <div className="mx-3">
                          {Array(value).fill(0).map((_, i) => (
                            <FontAwesomeIcon icon={faStar} key={i} style={{ fontSize: "15px" }} />
                          ))}
                          {Array(value2).fill(0).map((_, i) => (
                            <FontAwesomeIcon icon={faStar} key={i} style={{ color: "#f2f0ee" }} />
                          ))}
                        </div>
                      </div>
                      <h5 className="fw-bold text-end mt-3 mb-2 px-4">{product.top_price_action} SR</h5>
                      <p>{product?.product.description}</p>
                    </div>
                    <div className="product-details">
                      <div className="d-flex over-product text-start px-3 mt-3">
                        {product?.product.is_fav === false ? (
                          ``
                        ) : (
                          <>
                            <p className="text-green px-2 cursor-pointer font-12">متوفر</p>
                            <span className="mt-2"></span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="card-info">
                      <p>
                        Data: {format(new Date(data.data[0].start_date), "yyyy-MM-dd HH:mm:ss")}
                      </p>
                      <p className=" mx-2 mb-0 pb-0">
                        <p>
                          {" "}
                          <span className="fw-bold">Start Date</span> {product?.start_date}{" "}
                        </p>
                        <p>
                          {" "}
                          <span className="fw-bold">End Date</span> {product?.end_date_m} hours,{" "}
                          {product?.end_date_y}{" "}
                        </p>
                        {/* minutes, {product?.seconds} seconds */}
                      </p>

                      <p className="pb-2 mx-2">
                        <span className="fw-bold">Price</span> {product?.product.price} SR
                      </p>
                    </div>
                  </div>
                </Link>
                <div className="pb-3">
                {localStorage.getItem('UserToken') ? ( // تحقق من وجود الرمز المميز في localStorage
  <Button
    className="btn"
    onClick={() => toggleOpen(product.id)} // استخدم تبديل الحالة فقط للعنصر المحدد
    aria-controls={`example-collapse-text-${product.id}`}
    aria-expanded={open[product.id]} // استخدم الحالة المحددة للعنصر
  >
    ارسال قيمه المزاد
  </Button>
) : (
  <p> <Link to="/login"     className="btn btn-primary text-white">  ارسال قيمه المزاد</Link>   </p>
)}

                  <Collapse in={open[product.id]}>
                    <div id={`example-collapse-text-${product.id}`}>
                      <AuctionPost pro={product.id} />
                    </div>
                  </Collapse>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="text-center mt-5">
               <div className="d-center">
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#4fa94d"
          ariaLabel="ball-triangle-loading"
          wrapperClass={{}}
          wrapperStyle=""
          visible={true}
        />
      </div>
        </div>
      )}
    </>
  );
};

export default Auction;
