import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from "yup";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext/UserContext';
import { useTranslation } from 'react-i18next';

export default function LoginSubmit() {
    const { t } = useTranslation();

    let { setUserToken, setUserData, BasUrl3, en } = useContext(UserContext);
    let navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [falsedata, setfalsedata] = useState(true)
    const [initialLoad, setInitialLoad] = useState(true);

    async function handleLoginSubmit(values) {
        try {
            setIsLoading(true);
            const { data } = await axios.post(`https://dashboard.cmask.net/api/login${en}`, values);
// console.log(data);
if(data.status === true){
    navigate("/")
    localStorage.setItem('UserToken', data.data.token);
    window.location.reload();
}
if(data.status === false){
    setIsLoading(false);
    setfalsedata(data.message);
    setInitialLoad(false)
}
if (error.response) {
    // console.error("Server Error:", error.response.data);
    setError(error.response.data.message);
    setfalsedata(error.response.data.message);
} else {
    // console.error("Error:", error.message);
    setError("An error occurred");
    setfalsedata("An error occurred");
}

        } catch (error) {
            setIsLoading(false);
            setfalsedata(error.response.data.message);

            // console.log(false , "|falsefalsefalse");
            if (error.response) {
                // console.error("Server Error:", error.response.data);
                setError(error.response.data.message);
            } else {
                // console.error("Error:", error.message);
                setError("An error occurred");
            }
        }
    }

// console.log(initialLoad);
    function validate(values) {
        console.log(values); // Log form values
    }

    const saudiArabianPhoneNumberPattern = /^\+966[0-9]{7}$/;

    let validationSchema = yup.object({
        phone: yup.string().matches(saudiArabianPhoneNumberPattern, "Saudi Arabian phone number is not valid").required("Phone number is required"),
        password: yup.string().required("Password is required"),
    });
    let formik = useFormik({
        initialValues: {
            phone: "",
            password: "",
        },
        validationSchema,
        onSubmit: handleLoginSubmit
    });
// console.log(falsedata , "uipeaFGWERJGBI");
    return (
        <div className="container">
            <div className="row">
                <div className="col-4"></div>
                <div className='col-lg-5 col-md-12 py-5'>
                {!initialLoad ?<div className='alert alert-danger'>{falsedata}</div>:""}

                    <form onSubmit={formik.handleSubmit} className='w-100'>
                        <h5 className='fw-bold text-center'> {t('loginaount')} </h5>
                        <div className=''>
                            <div className=''>
                                <div>
                                    <label htmlFor='phone' className='mt-2 mx-3 '>{t('phonenumber')} :</label>
                                    <input
                                        type='tel'
                                        className='form-control mt-2 w-100 d-r '
                                        id="phone"
                                        placeholder={t('phonenumber')}
                                        onBlur={formik.handleBlur}
                                        name='phone'
                                        value={formik.values.phone}
                                        onChange={(e) => {
                                            let phoneNumber = e.target.value;
                                            phoneNumber = phoneNumber.replace(/\D/g, '');
                                            phoneNumber = "+" + phoneNumber;
                                            formik.setFieldValue('phone', phoneNumber);
                                        }}
                                    />
                                    {formik.errors.phone && formik.touched.phone && <div className='alert alert-danger'>{formik.errors.phone}</div>}
                                </div>
                                <div>
                                    <label htmlFor='password' className='mt-2 mx-3 '> {t('password')}</label>
                                    <input
                                        type='password'
                                        placeholder={t('password')}
                                        className='form-control mt-2 w-100'
                                        id="password"
                                        onBlur={formik.handleBlur}
                                        name='password'
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.password && formik.touched.password && <div className='alert alert-danger'>{formik.errors.password}</div>}
                                </div>
                            </div>
                        </div>
                        {isLoading ? (
    <button className='btn bg-main btn-add-card mt-2' type="button">
        <button className='btn bg-color btn-add-card p-0 m-0 '>  <i className="fas fa-spinner fa-spin"></i></button>
    </button>
) : (
    <button disabled={!(formik.isValid && formik.dirty)} className='btn btn-add-card w-100 border-radius p-8' type="submit"> {t('loginaount')}</button>
)}

                        <p className='mt-4 text-center'>This site is protected by reCAPTCHA and the Google Policy and Terms of Service apply</p>
                        <div className='d-center flex-column'>
                            <div className='d-flex mt-3'>
                                <p className='mx-3 mt-3'>{t('customer')}?</p>
                                <Link to={"/Register"} className='mt-3'>{t('Createyour')}</Link>
                            </div>
                            <div className='d-flex '>
                                <p className='mx-3'>{t('lostpassword')} ?</p>
                                <Link to={"/ForgetPassword"} className='  '>{t('Recoverpassword')}</Link>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
    );
}