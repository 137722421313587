import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../UserContext/UserContext';
import axios from 'axios';
import { useQuery } from 'react-query';

export default function Privacypolicy() {

    let { setUserToken, UserToken, BasUrl, BasUrl3, counterCart , en } = useContext(UserContext)

    
    function getFeaturProductwif() {
      return axios.post(`${BasUrl3}home${en}`)
  
    }
    let { data, isError, isLoading } = useQuery("feadurProducts", getFeaturProductwif)

    const [settings, setSettings] = useState(null);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      async function fetchSettings() {
        try {
          const response = await axios.get(`https://dashboard.cmask.net/api/settings${en}`);
          setSettings(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      }
  
      fetchSettings();
    }, []);

  return (
    <div className='mt-5 container'>
    
    <div className='d-flex'>
    <p className='fw-bold mx-3'>terms</p>
      <p>{settings?.data[0].terms}  </p>
    </div>
    <div className='d-flex mt-4'>
    <p className='fw-bold mx-3'>about_us</p>
      <p>{settings?.data[0].about_us}  </p>
    </div>
    <div className='d-flex mt-4'>
    <p className='fw-bold mx-3'>image</p>
    <img src={settings?.data[0].image} className='img-fluid'/> 
    </div>
  
    
    
    <video width="1000" className='d-center w-100' height="500" controls >
      <source className='w-100' src="https://cmask.net/dashboard/public/Attachment/Settings/VID-20230807-WA0001%20(1).mp4" type="video/mp4"/>
     </video>
    
    </div>
  )
}
