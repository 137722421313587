import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Slider from "react-slick";
import { UserContext } from '../UserContext/UserContext';
import Favorite from '../../Favorite/FavoriteAdd';

export default function OfferSlider() {
  let { BasUrl3, en } = useContext(UserContext);
  const [data, setData] = useState(null); // Initialize data as null

  const [value, setValue] = useState(5);
  const [value2, setValue2] = useState(5 - value);
  const { t } = useTranslation();
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [offersData, setOffersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchOffers = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${BasUrl3}home`);
        const offers = response.data?.data?.products;
        setData( response.data.data.products)
        console.log(response.data.data.products);
        setOffersData(offers);
        if (Array.isArray(offers) && offers.length > 0) {
          const firstOffer = offers[0];
          const rate = parseInt(firstOffer?.rate || 0);
          setValue(rate);
          setValue2(5 - rate);
        }
      } catch (error) {
        console.error('Error fetching offers:', error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOffers();
  }, [BasUrl3, en])
  return <>
<div className='bg-white'>
    <div className="top-slider px-4 p-2 bg-white container">
      <div className="d-flex justify-content-between align-items-center">
      {/* <h2 className="font-13 fw-bold">المقترحات</h2> */}

        <h3 className="fw-bold">  {t("Latestoffers")}</h3>
      </div>
    </div>


<div className='container '>
    {
      data ? <Slider {...settings}>

        {data.map((offer) => <>
          <div className="product   post-slide  pt-0 mt-0 mb-4 mx-2 border border-2 " key={offer.id}>
          <Favorite prosuctId={offer.id} className='mx-4'/>
        <Link key={offer.id} to={`/ProductDitales/${offer.id}`} className='boxshow  '>


            <div className="product-img post-img text-center position-relative d-center flex-column">
             <div className="containe d-center" style={{ maxWidth: '100%' }}>
  <img
    src={offer.image}
    alt=""
    className=""
    height={200}
  />
</div>


              {offer.discount == 0 ? "" : <>
              <div className="bg-bluo text-white px-2 d-inline-block rounded-pill position-absolute title-product bg-danger">{offer.discount}  </div>
              </>}
              <div className="product-details " >
            <div className='d-flex justify-content-between'>
            <div className='mx-3'>
                      {Array(parseInt(offer?.rate)).fill(0).map((_, i) => (
                        <FontAwesomeIcon icon={faStar} key={i} style={{ fontSize: '19px' }} />
                      ))}
                      {Array(5 - parseInt(offer?.rate)).fill(0).map((_, i) => (
                        <FontAwesomeIcon icon={faStar} key={i} style={{ color: "rgb(201 194 189)" }} />
                      ))}
                    </div>
                
              </div>
            </div>
            
              <div className="d-flex justify-content-between mt-2 ">
                <h5 className="fw-bold text-end px-4 ">  {offer.price} SR     </h5>
                    
                <div className="d-flex over-product text-start px-3 mt-3 font-9 ">
                {offer.available == 1 ? <>
                 
                </> : <>
                <div className=" w-100">
                  <span className="mt-2 "></span>
                  <p className="text-green px-2 cursor-pointer bg-danger"> {t("unavailable")}</p>
                  <span className="mt-2 "></span>
                </div>
                </>}

              </div>
             
                </div>
              <p className='pb-3'>{offer.name.split(" ").slice(0, 10).join(' ')}</p>

            </div>
            <div className="mb-1 mx-3">
           <button  className='btn btnhover w-100 '>{t('addtocart')} </button>
           </div>         
            </Link>
          </div>
        </> )}

      </Slider> : ""
    }

</div>

</div>
  </>
}
