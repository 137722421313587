import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { BallTriangle } from 'react-loader-spinner';
import NameCatogry from './NameCatogry';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { UserContext } from '../UserContext/UserContext';
import SubSubCategory from './SubSubCatogry';

export default function SubNavId() {
  // <SubSubCategory propsx={"01126303082"} />

  let { BasUrl2 } = useContext(UserContext)
  const [value, setValue] = useState(5);
  const [value2, setValue2] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [subCategoryValue, setSubCategoryValue] = useState('Ahmedd011125588 ');


  let Params = useParams();

  useEffect(() => {
    if (Params.id) {
      setSelectedId(Params.id);
    }
  }, [Params.id]);
  function getCatogry(id) {
    return axios.get(`https://dashboard.cmask.net/api/productsCategory/${id}`);
  }

  const { isError, data, isLoading } = useQuery(['catogrySlider', selectedId], () => getCatogry(selectedId));


  useEffect(() => {
    if (data?.data?.data && Array.isArray(data.data.data.products)) {
      data.data.data.products.forEach((product) => {
        setValue(product.rate);
        setValue2(5 - product.rate);
      });
    }
  }, [data]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-sm-9 '>
          <div className='row'>
          <SubSubCategory  pramuesId={Params.id}/>
            {isLoading ? (
              <div className='col-sm-9'>
                <BallTriangle
                  height={100}
                  width={100}
                  radius={5}
                  color="#4fa94d"
                  ariaLabel="ball-triangle-loading"
                />
              </div>
            ) : !data?.data?.data || !Array.isArray(data?.data?.data[0]?.products) ? (
              <h1 className='text-danger'>No data available</h1>
            ) : (
              data?.data?.data[0].products.map((imgAds) => (
                <div className='col-sm-3' key={imgAds.id}>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <Link to={`/ProductDitales/${imgAds.id}`}>
                          <div className="product shadow post-slide pt-0 mt-0 w-100" key={imgAds.id}>
                            <div className="product-img post-img text-center position-relative">
                              <img src={imgAds.image} alt="" className="w-100" height={300} />
                              <div className='d-flex justify-content-between'>
                                <div className='mx-3'>
                                  {Array(value).fill(0).map((_, i) => (
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      key={i.id}
                                      style={{ fontSize: '15px' }}
                                    />
                                  ))}
                                  {Array(value2).fill(0).map((_, i) => (
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      key={i}
                                      style={{ color: "#f2f0ee" }}
                                    />
                                  ))}
                                </div>
                              </div>
                              <h5 className="fw-bold text-end px-4"> {imgAds.price} SAR</h5>
                              <p>{imgAds.name.split(" ").slice(0, 5).join('  ')}</p>
                            </div>
                            <div className="product-details">
                              <div className="d-flex over-product text-start px-3 mt-3 ">
                                {imgAds.available ? (
                                  <>
                                    <p className="text-green px-2 cursor-pointer font-12">متوفر</p>
                                    <span className="mt-2"></span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className='col-sm-3'>
          <NameCatogry />
        </div>
      </div>
    </div>
  );
}
