import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { UserContext } from '../UserContext/UserContext';

export default function NameCatogry() {
    let { BasUrl3 , en} = useContext(UserContext)
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(`${BasUrl3}categories${en}`);
                setData(response.data.data);
                setIsLoading(false);
            } catch (error) {
                setIsError(true);
                setIsLoading(false);
            }
        }

        fetchData();
    }, []);

    let content = null;

    if (isLoading) {
        content = <BallTriangle height={100} width={100} color="#4fa94d" />;
    } else if (isError) {
        content = <p>Error fetching data</p>;
    } else if (Array.isArray(data)) {
        content = data.map((product) => (

           <div className='d-flex mb-0 pb-0 justify-content-between boxshow ' key={product.id}>
             <p key={product.id} className='mx-3 cursor-pointer font-13 mt-2'>{product.name}</p>
             <p key={product.id} className='mx-3 cursor-pointer font-13 mt-2'> ({product.count_products})</p>
           </div>


        ));
    } else {
        content = <p>Data is not an array</p>;
    }

    return <>{content}</>;
}
