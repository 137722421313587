import React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function DeleteNotifications({ id }) {
  const handleDelete = async () => {
    let token = localStorage.getItem("UserToken");

    try {
      const response = await axios.delete(`https://dashboard.cmask.net/api/notifications/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Notification deleted successfully');
      if( response.data.status == true){
        toast(response.data.message)
      }
      console.log('Deleted Notification:', response.data);
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  return (
    <div>
      <button onClick={handleDelete} className='btn'><i className="fa fa-trash text-w" aria-hidden="true"></i> </button>
    </div>
  );
}
