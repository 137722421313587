import React, { useContext, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { UserContext } from '../Components/UserContext/UserContext';

const PromoCode = () => {
  const [promoCode, setPromoCode] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  let { BasUrl3 , en} = useContext(UserContext)
  const apiUrl = `${BasUrl3}promo-code/check${en}`; // Replace with your API URL
  const token = localStorage.getItem('UserToken');

  const handleInputChange = (event) => {
    setPromoCode(event.target.value);
  };

  const handleCheckPromoCode = async () => {
    try {
      const response = await axios.post(apiUrl, { promoCode }, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });

      if (response.data.valid) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }

      if (response.data.status === true) {
        setResponseMessage(response.data.message);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setIsValid(false);
      // Handle error if needed
      console.error('Error checking promo code:', error);
    }
  };

  return (
    <div>
      <div className='d-flex mt-4 mx-2'>
        <input
          type="text"
          placeholder="Enter promo code"
          value={promoCode}
          onChange={handleInputChange}
          className='form-control'
        />
        <button onClick={handleCheckPromoCode} className='btn border border-2'>Check</button>
      </div>
      <p className='text-danger mt-2'>{responseMessage}</p>
    </div>
  );
};

export default PromoCode;
