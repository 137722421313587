import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { BallTriangle } from 'react-loader-spinner';
import { useContext } from 'react';
import { UserContext } from '../UserContext/UserContext';
import DelateRevue from './DelateRevue';

import { useTranslation } from 'react-i18next';

export default function GetRevei({ pro }) {
  let { BasUrl3 , en} = useContext(UserContext)
  const { t } = useTranslation();

  const [value, setValue] = useState(5);
  const [value2, setValue2] = useState(0);

  const token = localStorage.getItem('UserToken');

  async function getReviews(token) {
    try {
      const response = await axios.get(`${BasUrl3}product-review/${pro}${en}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response);
      return response.data.data || []; // تأكد من عودة قائمة البيانات أو مصفوفة فارغة إذا لم يتم العثور على بيانات
    } catch (error) {
      console.error('Error fetching reviews:', error);
      return [];
    }
  }

  const { isError, data, isLoading } = useQuery("reviews", () => getReviews(token));

  useEffect(() => {
    if (Array.isArray(data)) {
      const product = data[0]; // تفرض أخذ العنصر الأول فقط كعنصر واحد في الاستعراض
      setValue(product?.rate || 5);
      setValue2(5 - (product?.rate || 5));
    }
  }, [data]);

  return (
    <>
      {
        isLoading ? (
          <div className='d-flex justify-content-center align-items-center' style={{ height: '80vh' }}>
            <BallTriangle
              height={100}
              width={100}
              radius={5}
              color="#4fa94d"
              ariaLabel="ball-triangle-loading"
              wrapperClass={{}}
              wrapperStyle=""
              visible={true}
            />
          </div>
        ) : (
          <div className='container mt-5'>
            <div className='row'>
              {
                Array.isArray(data) && data.map((product) => (
                  <div className='col-md-3 pt-3' key={product?.id}>
                    <div className="pt-0 mt-0 border border-2">
                      <div className="producg text-center position-relative">
                        <div className='d-flex justify-content-between pt-3'>
                        <DelateRevue idProduct={product.id}/>
                      
                        </div>
                        <h5 className="fw-bold text-start mt-1 px-4 d-flex">{t('name')}: <h4 className='mx-2'>{product?.user_name}</h4></h5>
                        <p className='pb-2 m-auto text-end px-4'><h5 className='fw-bold pb-3 pt-0 mb-0 pb-0'>{t('comments')}:</h5> {product?.comment} </p>
                      </div>
                      <div className='mx-3 pb-2 d-center '>
                            {
                              Array(value).fill(0).map((_, i) => (
                                <FontAwesomeIcon
                                  icon={faStar}
                                  key={i}
                                  style={{ fontSize: '20px' }}
                                />
                              ))
                            }
                            {
                              Array(value2).fill(0).map((_, i) => (
                                <FontAwesomeIcon
                                  icon={faStar}
                                  key={i}
                                  style={{ color: "rgb(201 194 189)" }}
                                />
                              ))
                            }
                          </div>
                    </div>
                  </div>
             
                ))
              }
            </div>
          </div>
        )
      }
    </>
  );
}
