import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';

const AuctionPost = ({pro}) => {
  // console.log(pro);
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const token = localStorage.getItem('UserToken');
      const response = await axios.post(
        `https://dashboard.cmask.net/api/actions` ,
        { price_action: values.priceAction, action_id: pro }, // تحديد القيمة الثابتة مباشرة هنا
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const action_id = pro; // تعيين action_id بقيمة افتراضية

      // console.log(response.data.data);
      // القيام بأي عمليات أخرى هنا بناءً على action_id

      console.log('Action ID:', action_id);
    } catch (error) {
      console.error('Error:', error);
    }
    setSubmitting(false);
  };

  return (
    <div>
      <Formik
        initialValues={{ priceAction: '' }}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors = {};
          if (!values.priceAction) {
            errors.priceAction = 'يرجى إدخال قيمة العملية السعرية';
          }
          return errors;
        }}
      >
        {({ isSubmitting }) => (
          <Form className='d-flex'>
            <label>
              Price Action:
              <Field type="number" className="w-100 border border-primary p-1 btn-outline-primary" name="priceAction" />
              <ErrorMessage name="priceAction" component="div" />
            </label>
            <Field type="hidden" name="actionId" value={8} /> {/* حقل actionId بقيمة ثابتة */}
         <div className='w-25 mt-4'>
         <button type="submit" disabled={isSubmitting} className='w-100 btn border border-primary p-1'>
              {isSubmitting ?<i class="fa-solid fa-spinner"></i>: 'ارسال'}
            </button>
         </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AuctionPost;
