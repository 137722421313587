import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../UserContext/UserContext';
import { useTranslation } from 'react-i18next';

function Profile() {

  let { BasUrl3 , en} = useContext(UserContext)
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    async function ShowProfile() {
      try {
        const token = localStorage.getItem('UserToken');
        const { data } = await axios.get(`${BasUrl3}profile`, {
          
          headers: {
            Authorization: `Bearer ${token}` // إرسال رمز الوصول
          }
        });
  // console.log(profileData , "profileDataprofileDataprofileDataprofileDataprofileData");

        setProfileData(data.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }

    ShowProfile();
  }, []);


  if (loading) {
    return <div className='container mt-5'>
      <p className='font-16'>  <i className="fas fa-spinner fa-spin font-16"></i></p>
    </div>
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className='container'>
      <h1>{t('rithmetic')}</h1>
      <p className='d-flex mt-4'>{t('name')}: <p className='mx-2 fw-bold'>{profileData.name}</p> </p>
      <p className='d-flex'>{t('mail')}: <p className='fw-bold mx-2 '>{profileData.email}</p> </p>
      <p className='d-flex'>{t('adresse')}:<p className='fw-bold mx-2'> {profileData.address}</p> </p>
      <p className='d-flex'>{t('phone')}: <p className='fw-bold mx-2'>{profileData.phone}  </p></p>

      <div className='mt-4'>
        <Link to={"/UpdateProfile"} className='mx-3 linkprofile'>  {t('update')}</Link>
        <Link to={"/changePassword"} className='linkprofile'>  {t('updatepassword')}</Link>
        <Link to={"/DeleteAccount"} className='mx-3 linkprofile'> {t('dleateaccount')}</Link>
        <Link to={"/ShowOrder"} className='mx-3 linkprofile'> {t('ieworders')}</Link>
        <Link to={"/GetFavorite"} className='mx-3 linkprofile'> {t('favorite')}</Link>
      </div>
     
    </div>
  );
}

export default Profile;
