import axios from "axios";
import { createContext, useContext } from "react";
import { UserContext } from "../UserContext/UserContext";

export const CartContext = createContext();

export const CartContextProvider = (props) => {
  const token = localStorage.getItem("UserToken");
  // let { BasUrl3 } = useContext(UserContext)

  const addToCart = async (ProductId) => {
    try {
      const response = await axios.post(
        `https://dashboard.cmask.net/api/cart/add-to-cart/${ProductId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  
  function deleteCartItem(id, count) {
    return axios.post(`https://dashboard.cmask.net/api/cart-item/delete-product-from-cart/${id}/${count}`, null,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
  function chaingcart(id, count, status) {
    const data = {
      status: status // أضفت status هنا
    };
  
    return axios.post(`https://dashboard.cmask.net/front-api/cart-item/change-amount/${id}/${count}`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
  }
  
  


async function clearCart() {
  try {
    const response = await axios.delete('https://dashboard.cmask.net/api/cart', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response.status === 200) {
      console.log('All products in the cart have been deleted');
      return; 
    } else {
      throw new Error('Failed to clear the cart'); 
    }
  } catch (error) {
    console.error('Failed to clear the cart:', error);
    throw error; 
  }
}

  
  

  // ----------------------------
  // function UpdatePeoduct(productid , count){
  //     return axios.put(`url productid ${productid}` , 
  //     {  count:count } , {token}  )

  //     .then((resores) => resores)
  //     .catch((err) => err)
  //   }



  return (
    <CartContext.Provider value={{ addToCart, deleteCartItem , clearCart , chaingcart}}>
      {props.children}
    </CartContext.Provider>
  );
};
