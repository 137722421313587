import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { BallTriangle } from 'react-loader-spinner';
import { Link, useParams } from 'react-router-dom';
import { UserContext } from '../UserContext/UserContext';
import { useContext } from 'react';

export default function SubSubCategory({ pramuesId }) {
  let { BasUrl3 , en } = useContext(UserContext);
  const [value, setValue] = useState(5);
  const [value2, setValue2] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [products, setProducts] = useState([]);
  let Params = useParams();

  useEffect(() => {
    if (Params.id) {
      setSelectedId(Params.id);
    }
  }, [Params.id]);

  function getCategory(pramuesId) {
    return axios.get(`${BasUrl3}  productsSubDepartment/${pramuesId}${en}`);
  }

  const { isError, data, isLoading } = useQuery(['categorySlider', selectedId], () => getCategory(selectedId));

  useEffect(() => {
    if (data?.data?.data && Array.isArray(data.data.data.products)) {
      setProducts(data.data.data.products);
      data?.data?.data?.products?.forEach((product) => {
        setValue(product.rate);
        setValue2(5 - product.rate);
      });
    }
  }, [data]);

  return (
    <div className='d-flex'>
      {isLoading ? (
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#4fa94d"
          ariaLabel="ball-triangle-loading"
        />
      ) : (
        data?.data?.data?.[0]?.products ? (
          data.data.data[0].products.map((product) => (
            <div className='mx-3' key={product.id}>
            <Link to={`/SubNavId/${pramuesId}`}>
                 <span className='text-primary cursor-pointer'>{product.name}</span>
            </Link>
             
            </div>
          ))
        ) : (
          <h1></h1>
        )
      )}
    </div>
  );
}
