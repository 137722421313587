import React, { useContext } from 'react';
import styles from './Navbar.module.css';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../Assets/images/freshcart-logo.svg';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { UserContext } from '../UserContext/UserContext';
import axios from 'axios';
import { useQuery } from 'react-query';
import SearchAPI from '../Search/Search';
import { useState } from 'react';
import { useEffect } from 'react';
import InputSearch from '../Search/InputSearch';
import { useTranslation } from 'react-i18next';
import SearchInput from '../Search/SearchInputComponent';

// import SubDebartNav from './SubDebartNav';
// import { changeLanguage } from './i18n';
const token = localStorage.getItem('UserToken');


export default function Navbar() {
  const [countercart, setCountercart] = useState(0);
  const [isLoadingLanguage, setIsLoadingLanguage] = useState(false);

  let { setUserToken, UserToken, handleLanguageChange, BasUrl3, counterCart, en, toggleLanguage, language, setLanguage } = useContext(UserContext)
  // console.log(Ahmed12 , 'SubDebartNavSubDebartNavSubDebartNavSubDebartNav');
  useEffect(() => {
    setCountercart(counterCart);

  }, [counterCart]);

  const { t } = useTranslation();
  function getFeaturProduct() {
    return axios.post(`${BasUrl3}home${en}`)

  }
  let { data, isError, isLoading } = useQuery("feadurProducts", getFeaturProduct)
  // console.log(data?.data?.data?.categories[0].name, "nananababsnsnjsji");
  let Navegat = useNavigate()
  function LogOut() {
    localStorage.removeItem("UserToken")
    setUserToken(null)
    Navegat("/login")
  }


  return <>




    <div className="header-posiion">
      <header className="main-header d-none d-lg-block">
   <div className="col-4">
   <div className="mx-3  border-0 d-flex " style={{ position: 'relative' }}>

<div className="d-flex mx-5">
<span className='mt-1'>
<i class="fa-solid fa-globe text-w font-14"></i>
</span>
<select id="languageSelect" value={language} onChange={handleLanguageChange} className="border-0 text-w border-0 mx-2 px-0 outline" style={{ paddingRight: '2rem' }}>
  <option value={0}>English</option>
  <option value={1}>اللغة العربية</option>
</select>


</div>
  {/* <i className="bi bi-globe" style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}></i> */}
</div>


   </div>
                  <div className="border "></div>
        <div className="container">

          <div className="row">

            <div className="col-lg-2  pt-1">
              <Link to={"./"}>
                <img src="./logoCmask.png"
                  alt="logo" className="logo" />
              </Link>
            </div>
  <div className="col-lg-4">
  {!isLoading && (
    <>
      {language === 1 ? (
        <div className="mt-2">
            
            <div className={`rounded-right search-header d-flex justify-content-center align-items-center mt-2 ${language === 1 ? 'rtl' : 'ltr'}`}>
              <i className={`fas fa-search text-white ${language === 1 ? 'flip-horizontal' : ''}`}></i>
            </div>
        </div>
      ) : (
        <div className="mt-2">
     
            
            <SearchInput className='w-100' />
        </div>
      )}
    </>
  )}
</div>




            {/* } */}


            <div className="col-lg-1 text-end mt-1">
              <div className="d-flex justify-content-around">

                <div className=" mt-1">
              

                  
            


                </div>
              </div>
            </div>


  
            <div className="col-1"></div>

            {UserToken == null ? <>
              <div className='col-sm-3 mt-3'>
                <div className="d-center">
                  <div className="shopping-cart position-relative mx-3">
                    <Link to={"/login"} className="color-headre text-white hoverlenk"> <span className="fs-4"><i
                      className="fa-solid fa-cart-shopping font-30"></i></span></Link>
                          <Link to={"/login"} className='text-w hoverlink'>  <i class="fa-solid fa-bell  font-14   text-w iconehover"></i></Link>
                    

                  </div>
                  <div className="mx-3">
                    <Link to={"/login"} className='hoverlenk'>
                    <span className="fs-4"><i
                    className="fa-solid fa-cart-shopping font-14 text-w"></i></span>
                    </Link>
                    

                    
                    

                  </div>
       <Link to={"./login"}>
   <div className='cursor-pointer font-13 text-dark mt-2 text-w' > <span className='text-w'>{t('loginaount')} </span><span className='mx-1'><i className="fas fa-sign-in text-w"></i></span> </div>
   </Link>

                </div>
                </div>
  
        
        
            </> : <div className="col-sm-4 mt-3 d-flex justify-content-end ">
              <div className="d-center">
                {/* <div className="shopping-cart position-relative mt-5">
                  <Link to={"/Cart"} className="color-headre text-dark mt-2"> <span className="fs-4"><i
                    className="fa-solid fa-cart-shopping font-30"></i></span></Link>
                  <div className="position-absolute counter-shop font-15 text-white">
                    {countercart}
                  </div>

                </div> */}
                <div className="mx-3">
                {/* <Link to={"/Notifications"} className=''>  <i class="fa-solid fa-bell text-dark"></i></Link> */}
   
             


                </div>
              </div>
              


              <div className=" mt-1  ">


{UserToken == null ? <>
  <Link to={"Login"} className="btn text-white font-15">
  {t("login")} <span className='mx-2'><i className="fa fa-sign-in" aria-hidden="true"></i></span>  </Link>
</> : <>
  <div className='d-flex'>
    <div className='cursor-pointer font-13 text-dark mt-2 text-w' onClick={() => LogOut()}> <span className='text-w'>{t('loginbtn')} </span><span className='mx-1'><i className="fas fa-sign-in text-w"></i></span> </div>
    <Link className='text-dart text-none mt-2' to={"/Showprofile"}>
      <p className='mx-3 d-flex font-13 text-w'> {t('rithmetic')} <span className='mx-1'><i className="fa-solid fa-user text-w"></i></span></p>
    </Link>
    <Link to={"/Notifications"} className='text-w hoverlenk'>  <i class="fa-solid fa-bell  font-14  text-w iconehover"></i></Link>
    <div className="shopping-cart position-relative  mx-3">
                  <Link to={"/Cart"} className="color-headre text-dark hoverlenk"> <span className="fs-4  p-6"><i
                    className="fa-solid fa-cart-shopping iconehover font-14 text-w"></i></span></Link>
                  {/* <div className="position-absolute counter-shop font-15 text-white">
                    {countercart}
                  </div> */}

                </div>
  </div>
  <p>  </p>
</>}
</div>


            </div>}


          </div>


        </div>

      </header>
      {/* ---------------------------------------------------------------------------- */}
      <div className="container-fluid  d-none d-lg-block mb-0 bg-orange">
        <nav className="navbar navbar-expand-lg navbar-light  mb-0 pb-0">
          <button className="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContentt" aria-controls="navbarSupportedContentt" aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse mx-3 mb-2" id="navbarSupportedContentt">
            <ul className="navbar-nav mr-auto">
              {isLoading?   "":<Link to={"/"} className='  mt-1 fw-bold text-white '>{t('home')} </Link>}
         


              {
                data && data.data && data.data.data && data.data.data.categories_header && data.data.data.categories_header?.map((nav) =>
                  <>
                    {/* {console.log(data?.data?.data?.categories?.sup_department[1].name.split(" ", 2).join(" "))} */}

                    <div className="dropdown" key={nav.id}>
                      {/* {console.log(nav.sup_department, "Loading")} */}
                      <button className="btn dropdown-toggle nav-item dropdown pt-0 mt-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className='mx-1'><i className="fa-solid fa-chevron-down text-white"></i></span>
                        {nav?.name && nav?.name.split('').slice(0, 25).map((word, index) => (
                          <span className='font-15 text-white' key={index}>{word}</span>
                        ))}
                      </button>
                      {/* --------------------------------------------------------------------------------- */}


                      <ul className="dropdown-menu text-end" aria-labelledby="dropdownMenuButton">
                        <li className="dropdown-item ">
                          {nav.sup_department?.map((pro) => <div className='hoverNav p-2'>
                            <Link className='  ' to={`./Category/${pro.id}`}>

                              <li className='flex-column ' key={(pro.id)}>  </li>
                              <li className=' px-2 hoverNa'>{(pro?.name)}</li>
                            </Link>
                          </div>
                          )}
                        </li>
                        <span className="arrow-up"></span> 
                      </ul>
                    </div>
                  </>
                )
              }
              {isLoading?    "":<Link to={"Auction"} className='  mt-1 fw-bold text-white'>{t('auction')} </Link>}
          
            </ul>
          </div>
        </nav>
      </div>
      {/* ---------------------------------------------------------------------------- */}

      <div className="responde-vheader d-lg-none mb-0">
        <nav className="navbar navbar-expand-lg navbar-light bg-min-color mb-0 pb-0">
          <div className="navbar-toggler d-flex">
            <button className="navbar-toggler text-white border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              {/* <span className="navbar-toggler-icon text-white "></span> */}
              <span className='fa-lg border-0'><i class="fas fa-bars"></i></span>
            </button>
          </div>
          <Link className="navbar-brand" >
            <div className="d-flex mx-3 ">
              <Link to={"Login"} className=''>

                <span className="mx-2 text-white"> <i className="fa-solid fa-user font-17 text-white"></i></span>
              </Link>
              <span className="mx-2 text-white"> <i className="fas fa-search font-17"></i></span>
              <Link to={"Cart"}>
                <span className="mx-2 text-white"> <i className="fa-solid fa-cart-shopping font-17"></i></span>
              </Link>
            </div>
          </Link>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <div className="accordion" id="accordionExample">

                  {
                    data && data.data && data.data.data && data.data.data.categories_header && data.data.data.categories_header?.map((nav) =>
                      <div className='bg-white'>

                        <div className="dropdown " key={nav.id}>
                          
                          <button className="btn  dropdown-toggle nav-item dropdown" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            {nav.name} <span className='mx-3'>  <i class="fa-solid fa-chevron-down"></i></span>
                          </button>
                          <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton">
                            <li className="dropdown-item ">

                              {nav?.sup_department?.map((pro) => <div className=''>

                                <Link className=' hoverNav' to={`./Category/${pro.id}`}>
                                  <li className='flex-column ' key={(pro.id)}>  </li>
                                  <li className=' px-2'>{(pro.name)}</li>
                                </Link>
                              </div>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    )
                  }
                </div>
              </li>
              <div className=" bg-white mt-0">
              {/* --------------------------------------------------------------------------------- */}
                <div className="mx-3">
                  <h6 className="mt-3 text-danger fw-bold mx-4">افضل العروض</h6>
                  <div className="w-100 mt-3 mb-3 h-1"></div>
                  <label htmlFor="" className="text-color mx-4">اللغه</label>
                  <select value={language} onChange={handleLanguageChange} className='form-control'>
                    <option value={0}>English </option>
                    <option value={1}> Arabic</option>
                  </select>
                  <div className="w-100 mt-3 mb-3 h-1"></div>
                  <div className="mt-3">
                    <h6 className="fw-bold text-color mt-2 mx-4">تحتاج للمساعده؟</h6>
                    <div className="mt-2 d-flex mx-4">
                      <span className="mx-2"><i className="fa-solid fa-phone"></i></span>
                      <span className="mx-2">اتصل بنا 010555</span>

                    </div>
                    <div className="mt-2 d-flex mx-4">
                      <span className="mx-2"><i className="fa fa-envelope" aria-hidden="true"></i></span>
                      <span className="mx-2"> info@kimostore.net</span>

                    </div>
                    <div className="w-100 mt-3 mb-3 h-1"></div>

                    <h6 className="fw-bold text-color mx-4">إنضم الي متابعينا</h6>
                    <div className="mt-2 d-flex hover-icone-header mx-4">
                      <div className="mx-2 icone-hoder d-center"><i className="fa fa-envelope"
                        aria-hidden="true"></i></div>
                      <span className="mx-2"> Facebook</span>
                    </div>
                    <div className="mt-2 d-flex hover-icone-header mx-4">
                      <div className="mx-2 icone-hoder d-center"><i className="fa-brands fa-instagram"></i>
                      </div>
                      <span className="mx-2"> instagram</span>
                    </div>
                    <div className="mt-2 d-flex hover-icone-header mx-4">
                      <div className="mx-2 icone-hoder d-center"><i className="fa-brands fa-youtube"></i>
                      </div>
                      <span className="mx-2"> Yuotube</span>
                    </div>
                    <div className="mt-2 d-flex hover-icone-header mx-4">
                      <div className="mx-2 icone-hoder d-center"><i className="fa-brands fa-tiktok"></i></div>
                      <span className="mx-2"> tiktok</span>
                    </div>
                    <div className="mt-2 d-flex hover-icone-header mx-4">
                      <div className="mx-2 icone-hoder d-center"><i className="fa-brands fa-linkedin-in"></i>
                      </div>
                      <span className="mx-2"> linkedin</span>
                    </div>
                  </div>

                </div>
              </div>

            </ul>

          </div>


        </nav>
      </div>
    </div>

    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasRightLabel">Offcanvas right</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body overflow-auto">
        {
          data && data.data && data.data.data && data.data.data.categories && data.data.data.categories?.map((nav) =>
            <>


              <div className="dropdown mt-2" key={nav.id}>

                {/* {console.log(nav.sup_department, "Loading")} */}
                <button className="btn dropdown-toggle nav-item dropdown" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                  <span className='mx-1'><i className="fa-solid fa-chevron-down"></i></span>
                  {nav?.name && nav?.name.split('').slice(0, 25).map((word, index) => (
                    <span key={index}>{word}</span>
                  ))}
                </button>

                <ul className="dropdown-menu " aria-labelledby="dropdownMenuButton">
                  <li className="dropdown-item ">

                    {nav.sup_department?.map((pro) => <>
                      <Link className=' hoverNav' to={`./Category/${pro.id}`}>
                        <li className='flex-column ' key={(pro.id)}>  </li>

                        <li className=' px-2'>{(pro.name)}</li>

                      </Link>
                    </>
                    )}
                  </li>
                </ul>
              </div>
            </>
          )
        }
      </div>
    </div>




  </>
}
