import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../UserContext/UserContext';

const SearchInput = () => {
  const [searchQuery, setSearchQuery] = useState('');
  let { language, setLanguage } = useContext(UserContext)

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleButtonClick = (event) => {
    setSearchQuery(searchQuery); // Using searchQuery directly


  };

  return (
    <div className='d-flex'>
        <Link to={`/search?name=${searchQuery}`}>
      <div className={`rounded-right search-header d-flex justify-content-center align-items-center mt-2 ${language === 1 ? 'rtl' : 'ltr'}`}>
              <i className={`fas fa-search text-white ${language === 1 ? 'flip-horizontal' : ''}`}></i>
            </div>      </Link>
      <input
        type="text"
        placeholder="Search..."
        className='bg-footer mt-2 w-75'
        value={searchQuery}
        onChange={handleInputChange}
      />
    
    </div>
  );
};

export default SearchInput;
