// PaymentForm.js
import React, { useState } from 'react';
import axios from 'axios';

const Categories = () => {
  const [paymentData, setPaymentData] = useState({
    amount: 100.00, // قم بتعيين المبلغ المطلوب
    currency: 'USD', // قم بتعيين العملة المطلوبة
    // أضف أي معلمات إضافية مطلوبة
  });

  const handlePayment = async () => {
    try {
      const response = await axios.post(
        'YOUR_PAYTABS_API_ENDPOINT',
        paymentData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer YOUR_PAYTABS_API_KEY',
          },
        }
      );

      // اتخاذ إجراء بناءً على رد PayTabs (مثل التوجيه إلى صفحة الدفع)
      console.log(response.data);
    } catch (error) {
      console.error('خطأ في الدفع:', error);
    }
  };

  return (
    <div>
      <h2>نموذج الدفع</h2>
      <button onClick={handlePayment}>إجراء الدفع</button>
    </div>
  );
};

export default Categories;
