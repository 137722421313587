import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../UserContext/UserContext';
import { BallTriangle } from 'react-loader-spinner';
import { CartContext } from './CartContext';
import { toast } from 'react-toastify';
import PromoCode from '../../Promo-code/Promo-code';
import { useTranslation } from 'react-i18next';

export default function Cart() {
  const { deleteCartItem, counterPrices, clearCart, chaingcart } = useContext(CartContext);
  const [price, setPrice] = useState(2)
  const [order, setorder] = useState(0)

  const { BasUrl3 , en  } = useContext(UserContext);
  // console.log(en ,`carttyyyy`);
  const token = localStorage.getItem('UserToken');
  let counterPrice = 0;

  const { t } = useTranslation();


  const [SercartDetails, setSercartDetails] = useState(null)





function UpdatePeoduct(id, count, status) {
  const data = {
    status: status // أضفت status هنا
  };
  console.log(data);

  return axios.post(`https://dashboard.cmask.net/api/cart-item/change-amount/${id}/${count}`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    throw error;
  });
}


  async function handleClearCart() {
    try {
      const data = await clearCart();
      console.log('Cart cleared successfully', data);
    } catch (error) {
      console.error('Failed to clear cart', error);
    }
  }


  async function deleteCartItemFunction(id, count) {
    let { data } = await deleteCartItem(id, count);
    setSercartDetails(data)
    toast.success(data.message, {
      position: 'top-center'
    })
  }


  function getCartItems() {
    return axios.get(`${BasUrl3}cart/show-cart${en}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  const { isError, data, isLoading } = useQuery('cartItems', getCartItems);


  counterPrice = data?.data?.data?.cartItems?.reduce((total, imgAds) => {
    let to= total + imgAds.price;
  }, 0) || 0;
  const totalPriceSum = data?.data?.data.cartItems.reduce((accumulator, imgAds) => {
    const totalPrice = imgAds.price * imgAds.totalAmount;
    return accumulator + totalPrice;
  }, 0);
  
  // console.log("Total Price Sum:", totalPriceSum);
  
  
// console.log(data?.data?.data.cartItems);
data?.data?.data.cartItems.map((item) => {
  let totalPrice=item.price* item.totalAmount
  // console.log(totalPrice , "asdjfk");
})
  let totalProic = data?.data?.data.total_quantity
  let AllOrder = data?.data?.data.total_quantity

  useEffect(() => {
    setPrice(counterPrice * totalProic);
  }, [totalProic, counterPrice]);
  
  useEffect(() => {
    setorder(AllOrder);
  }, [AllOrder]);





  if (isLoading) {
    return (
      <div className='d-center'>
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#4fa94d"
          ariaLabel="ball-triangle-loading"
          wrapperClass={{}}
          wrapperStyle=""
          visible={true}
        />
      </div>
    );
  }

  if (isError || !data || !data.data || !data.data.data || !data.data.data.cartItems) {
    return <div>Error loading data</div>;
  }



  return (
<div className='container mt-4'>
  <h4 className='fw-bold'>{t('cart')}</h4>
  
  <p className='d-flex mt-5'>{t('total')} <h5 className='fw-bold mx-2 min-color'>{totalPriceSum} SR</h5></p>
  <p className='d-flex mt-1'>{t('orders')}  <h5 className='fw-bold mx-2 min-color'>{data?.data?.data.total_quantity} طلبات</h5></p>
  
  <div className='row'>
    {/* Left Section */}
    <div className='col-lg-8 col-md-12 mt-5'>
      <div className='bordreSolid'>
        <div className='row pt-3 mx-2'>
          {/* Product */}
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-4 text-center d-center'>
              <h4 className='mt-3 text-center'>{t('Product')}</h4>
              </div>
              <div className='col-4 text-center'><h4 className='mt-3'>{t('total')}</h4></div>
              <div className='col-4'>
              <h4 className='mt-3 text-center'>Quantity</h4>
              </div>
            </div>
            <div className='mt-4'>
              {/* Product items */}
              {data.data.data.cartItems.map((imgAds) => (
               <div className='box-shado'>
                <div className='row'>
             <div className='col-6'>
             <div className='d-flex' key={imgAds.id}>
                  <div className='w-30'>
                    {imgAds.image && <img src={imgAds.image} className='w-100' alt={imgAds.name} />}
                  </div>
                  <div className='mx-3'>
                    <p className='mt-4 fw-bold'>{imgAds.name}</p>
                  <div className='d-flex'>
                  <p className='fw-bold'>{imgAds.price} SR</p>
                    <div className='fw-bold cursor-pointer  text-danger mx-5' onClick={() => deleteCartItemFunction(imgAds.product_id, data?.data.data?.cart_id)}>{t('delete')}</div>
                 
                  </div>
                 
                  </div>
                </div>
             </div>
           <div className='col-3'>
           <div className='mt-4 bordreSolid w-100 d-flex justify-content-between' key={imgAds.id}>
           <button className='btn btn-bluse ' key={imgAds.id} onClick={() => UpdatePeoduct(imgAds.product_id, data.data.data?.cart_id, 1)}><i className="fa-solid fa-plus"></i></button>
                <h5 className='mt-1 fw-bold'>{imgAds.totalAmount}</h5>
                <button className='btn btn-mainuse' key={imgAds.id} onClick={() => UpdatePeoduct(imgAds.product_id, data.data.data?.cart_id, 0)}><i className="fa-solid fa-minus"></i></button>
              </div>
           </div>
          <div className='col-3'>
          <div className='mx-3 ' key={imgAds.id}>
                <p className='fw-bold pt-7 '>{imgAds.price*imgAds.totalAmount}  SR</p>
              </div>
          </div>
          <div className='border border-1'></div>
                </div>
               </div>
              ))}
            </div>
          </div>
          {/* Quantity */}
      
        
        </div>
      </div>
    </div>
    {/* Right Section */}
    <div className='col-lg-4 col-md-12 mt-5'>
      <div className='bordreSolid'>
        <div className='pt-4 d-flex mx-3 justify-content-between'>
          <h5 className='fw-bold'>{t('total')}</h5>
          <h5 className='fw-bold'>{totalPriceSum} SR</h5>
        </div>
        <p className='mx-3'>Taxes Not Included. Shipping calculated at checkout</p>
        <div className='text-center mx-auto mt-3 mb-4'>
          <button className='chekout btn text-white w-75 mx-auto pt-2 text-center'>{t('checkout')}</button>
          <PromoCode />
        </div>
      </div>
    </div>
  </div>
</div>

  

  );
}
