import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './Components/Home/Home'
import Products from './Components/Products/Products'
import Cart from './Components/Cart/Cart'
import Brands from './Components/Brands/Brands'
import Login from './Components/Login/Login'
import Register from './Components/Register/Register'
import Categories from './Components/Categories/Categories'
import Layout from './Components/Layout/Layout'
import ForgetPassword from './Components/ForgetPassword/ForgetPassword';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import UserContextProvider, { UserContext } from './Components/UserContext/UserContext';
import ProtuctRout from './ProtecdRout/ProtuctRout';
import { useContext, useEffect } from 'react';
import Showprofile from './Components/Showprofile/Showprofile';
import UpdateProfile from './Components/Showprofile/UpdateProfile';
import ChangePassword from './Components/Showprofile/changePassword';
import DeleteAccount from './Components/Showprofile/DeleteAccount';
import ProductDitales from './Components/ProductDetalis/ProductDitales';
import Notfaunt from './Components/NotFautnt/Notfaunt';
import Category from './Components/Catogry/Category';
import { CartContextProvider } from './Components/Cart/CartContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShowOrder from './Components/Showprofile/ShowOrder';
import Pending from './Components/Showprofile/pending';
import Shipping from './Components/Showprofile/shipping';
import SubSubCategory from './Components/Catogry/SubSubCatogry';
import SubNavId from './Components/Catogry/SubNavId';
import ProductSearch from './Components/Search/ProductSearch';
import SearchAPI from './Components/Search/Search';
import ProductReview from './Components/Ratings/product-review';
import GetFavorite from './Favorite/GetFavorite';
import PromoCode from './Promo-code/Promo-code';
import Auction from './Components/Auction/Auction';
import AuctionPost from './Components/Auction/AuctionPost';
import Terms from './Components/Footer/terms';
import Guide_file from './Components/Footer/Guide_file';
import About_us from './Components/Footer/About_us';
import Privacypolicy from './Components/Footer/privacy-policy';
import Countries from './Components/Footer/Countries';
import Cities from './Components/Footer/Cities';
import PaymentForm from './Components/payment/PaymentForm';
import Text from './Text';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { onMessage } from 'firebase/messaging';
import { generateToken, messaging } from './firebase';
import Notifications from './Notifications/Notifications';
import SearchInput from './Components/Search/SearchInputComponent';

let routes = createBrowserRouter([
  { path: '/', element: <Layout />, children: [
    {index:true , element:<Home/>},
    {path:'Products' , element:<Products/>},
    {path:'Cart' , element: <ProtuctRout><Cart/></ProtuctRout>},
    {path:'Categories' , element:<Categories/>},
    {path:'Brands' , element: <ProtuctRout><Brands/></ProtuctRout>},
    {path:'UpdateProfile' , element: <ProtuctRout><UpdateProfile/></ProtuctRout>},
    {path:'ChangePassword' , element: <ChangePassword/>},
    {path:'DeleteAccount' , element: <ProtuctRout><DeleteAccount/></ProtuctRout>},
    {path:'Search' , element:<SearchAPI/>},
    {path:'Terms' , element:<Terms/>},
    {path:'guide_file' , element:<Guide_file/>},
    {path:'about_us' , element:<About_us/>},
    {path:'UserContextProvider' , element:<UserContextProvider/>},
    {path:'privacypolicy' , element:<Privacypolicy/>},
    {path:'ProductReview' , element:<ProductReview ></ProductReview>},
    {path:'SubNavId/:id' , element: <ProtuctRout><SubNavId/></ProtuctRout>},
    {path:'SubSubCategory' , element: <SubSubCategory/>},
    {path:'countries' , element: <ProtuctRout><Countries/></ProtuctRout>},
    {path:'Cities/:id' , element: <ProtuctRout><Cities/></ProtuctRout>},
    {path:'PromoCode' , element: <ProtuctRout><PromoCode/></ProtuctRout>},
    {path:'Auction' , element: <Auction/>},
    {path:'ShowOrder' , element: <ProtuctRout><ShowOrder/></ProtuctRout>},
    {path:'Notifications' , element: <ProtuctRout><Notifications/></ProtuctRout>},
    {path:'Category/:id' , element: <Category/>},
    {path:'ProductDitales/:id' , element: <ProductDitales/>},
    {path:'AuctionPost' , element:<AuctionPost/>},
    {path:'Login' , element:<Login/>},
    {path:'GetFavorite' , element:<GetFavorite/>},
    {path:'ForgetPassword' , element:<ForgetPassword/>},
    {path:'UserContextProvider' , element:<UserContextProvider/>},
    {path:'Register' , element:<Register/>},
    {path:'ResetPassword' , element:<ResetPassword/>},
    {path:'Showprofile' , element:<Showprofile/>},
    {path:'PaymentForm ' , element:<PaymentForm />},
    {path:'SearchInput' , element:<SearchInput />},
    {path:'*' , element:<Notfaunt/>},
  ] }
])

function App() {
  // useEffect(()=>{
  //   generateToken()
  //   onMessage(messaging, (payload)=>{
  //     // console.log(payload) , "0000000000000000000";
  //   })
  // })
  return <I18nextProvider i18n={i18n}>
      <CartContextProvider>
      <UserContextProvider>
 <RouterProvider router={routes}></RouterProvider>
 
  </UserContextProvider>
  <ToastContainer />
  </CartContextProvider> 
  </I18nextProvider>
  

}

export default App;
