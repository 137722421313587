import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { UserContext } from '../UserContext/UserContext'
import { useTranslation } from 'react-i18next';
// import img from "./build/visa.png"


export default function Footer() {
    let { setUserToken, UserToken, BasUrl, BasUrl3, counterCart, en } = useContext(UserContext)
    function getFeaturProduct() {
        return axios.post(`${BasUrl3}home${en}`)
    }
  const { t } = useTranslation();

    let { data, isError, isLoading } = useQuery("feadurProducts", getFeaturProduct)

    const [settings, setSettings] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchSettings() {
            try {
                const response  = await axios.get(`https://dashboard.cmask.net/api/settings${en}`);
                setSettings(response.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        fetchSettings();
    }, []);
    // .log(settings);



    // console.log(data?.data?.data?.categories[0].name, "nananababsnsnjsji");



    return <>
        <footer className="  text-muted boxshow">


            <section className="container">
                {/* <div className="container  mt-5"> */}

                <div className="row mt-3">

                    <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4 pt-4">

                        <h6 className="mx-4 text-uppercase fw-bold d-center ">
                    

                        {t('links')}
                        </h6>
<div className="d-flex">
    
                        <div>   <img  className='h-114 w-100   ' src="https://taqiviolet.com/public/images/settings/mainimg.png"  />     </div> 
                        <div >   <img  className='h-144  w-100   ' src="https://taqiviolet.com/public/images/settings/marof.png" />     </div> 
                        <div >   <img  className='h-114 w-100   ' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQijpFRfZ3qEpNLJhcYFg-IR4lrLTPk7jBLTxsPxFSfZ6ZMoI3kiKkYtrFJE6FLAMCaSus&usqp=CAU" />     </div> 

</div>
<p className='mt-2 text-center '>
our app supports apple pay as a payment method
</p>


                    </div>

                    <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 pt-4">

                        <h6 className="text-uppercase fw-bold mb-4 mx-4 ">
                        {t('who')}
                        </h6>
                        <Link to={"./terms"}>  <p className='hoverfooter '> {t('terms')}</p></Link>
                        <Link to={"./guide_file"}>  <p className='hoverfooter '>guide_file</p></Link>
                        <Link to={"./About_us"}>  <p className='hoverfooter '> {t('about')}</p></Link>
                        <Link to={"./privacypolicy"}>  <p className='hoverfooter '> {t('privacypolicy')}</p></Link>
                        {/* <Link to={"./Categories"}>  <p>Categories</p></Link> */}

                        {/* -------------------------------------------------------------- */}
                    </div>

                    <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 pt-4">

                        <h6 className="text-uppercase fw-bold mb-4 mx-4 ">
                        {t('most')}
                        </h6>


                        {data?.data?.data?.categories_header?.map((nav, index) => (
                            <div key={index} className='pt-3 '>
                                <Link to={`./Category/${nav.id}`} className='hoverfooter  pt-5' key={nav.id}>
                                    {nav?.name}
                                </Link>
                                <div className="dropdown" key={`${nav.id}-dropdown`}>
                                    {/* يمكنك إضافة محتوى إضافي هنا إذا لزم الأمر */}
                                </div>
                            </div>
                        ))}






                    </div>
                    <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 pt-4">

                        <h6 className="text-uppercase fw-bold mb-4 mx-4 ">  {t('subscription')} </h6>
                        <p className="mx-4 ">to keep up to date with the latest arrivals andexclusive offers direct to your inbox.</p>

                        {/* <p className="mt-5 mx-4"><input type="email" placeholder="E-mail (ex@gmail.com)"
                            className="footer-inp form-control  mx-2" />
                            <button className="btn btn-primary  mt-3">Subscribe</button>
                        </p> */}

                    </div>


                </div>
            </section>

            <div className=" bg-black ">
                        <div className='container '>

<div className='row'>
    
<div className="col-md-4 pt-4 pb-3">
                    <div className="text-center text-white">
                        ©2024 - active4web | : cmask.net
                    </div>
                </div>
                <div className="col-md-4 pt-3 pb-3">
                    <div className="d-flex justify-content-around w-75 text-center ">

                        <div>   <img className='w-50 h-75' src="https://taqiviolet.com/public/images/payments/HMEiAdYdcAADPpu8IZpEpOLViGtVUkCdd9qJHXlW.png" />     </div>
                   <div>   <img src="https://taqiviolet.com/public/images/payments/CUAvOtJgaaBjj5eLjnuNu9GlE1vEtioROYkdLuy6.png" />     </div>
                        <div>   <img src="https://taqiviolet.com/public/images/payments/CUAvOtJgaaBjj5eLjnuNu9GlE1vEtioROYkdLuy6.png" />     </div>
                        <div>   <img src="https://taqiviolet.com/public/images/payments/oJaiBwJCGhZJolAaRDviCYI6D1quZw1i0fqAHG25.png" />     </div> 



                        {/* 

                <div className="footer-img">
                
                </div>
                <div className="footer-img">
                    <img src="img/logoFooter.png" className="w-100" alt=""/>
                </div> */}
                        {/* <!-- <div className="footer-img">
        </div> --> */}


                    </div>
                </div>

                <div className="col-md-4 d-flex justify-content-center pt-4 pb-3">


                    <div className="d-flex justify-content-around w-50">
                        <span className="icone-footer d-center"><Link to="icone-footer" className=" fs-4"><i
                            className="fa-brands fa-instagram"></i></Link></span>
                        <span className="icone-footer d-center"><Link to="icone-footer" className=" fs-4"><i
                            className="fa-brands fa-facebook-f"></i></Link></span>
                        <span className="icone-footer d-center"><Link to="icone-footer" className=" fs-4"><i
                            className="fa-brands fa-linkedin-in"></i></Link></span>
                        <span className="icone-footer d-center"><Link to="icone-footer" className=" fs-4"><i
                            className="fa-brands fa-whatsapp"></i></Link></span>
                    </div>
                    
                </div>
</div>


                        </div>
          


            </div>

        </footer>
    </>
}
