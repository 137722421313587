import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import Slider from "react-slick";
import { UserContext } from '../UserContext/UserContext';

export default function Ads() {
  let { BasUrl3, en } = useContext(UserContext);

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${BasUrl3}home${en}`);
        console.log(response.data.data.ads);
        setData(response.data.data.ads);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [BasUrl3, en]);

  return (
    <>
      {isLoading && <div>Loading...</div>}
      {isError && <div>Error fetching data</div>}
      {data && (
        <div className="container">
          <div className="row mt-0 ">
            <div className="col-sm-12">
              <img src={data?.image_name} className="w-100" />
            </div>

            {data?.map((imgAds) => (
              <div className="col-md-6 mt-5" key={imgAds.id}>
                <div className="discount-img w-100">
                  <img className="w-100 h-70" src={imgAds?.image_name} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}