import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Slider from "react-slick";
import { UserContext } from '../UserContext/UserContext';
import { useContext } from 'react';
import Favorite from '../../Favorite/FavoriteAdd';


export default function OfferSlider() {
  let { BasUrl3 , en} = useContext(UserContext)
  const { t } = useTranslation();

  
  const [value, setValue] = useState(5);
  const [value2, setValue2] = useState(5-value);


  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  function getCatogry() {
    return axios.post(`${BasUrl3}home?lang=ar`)
  }

  let { isError, data, isLoading } = useQuery("OfferSliderr", getCatogry)
  useEffect(() => {
    async function fetchOffers() {
      try {
        const response = await axios.get(`https://dashboard.cmask.net/api/home${en}`);
        const offersData = response.data?.data?.offers;
  
        if (Array.isArray(offersData) && offersData.length > 0) {
          const firstOffer = offersData[0]; // يفترض وجود التقييمات في العنصر الأول
          const rate = parseInt(firstOffer?.rate || 0);
  
          setValue(rate);
          setValue2(5 - rate);
        }
      } catch (error) {
        console.error('Error fetching offers:', error);
      }
    }
  
    fetchOffers();
  }, []);
  

  return <>
   <div className="top-slider px-4 p-2 mt-5 bg-white container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="top-slider px-4 p-2 bg-white">
            <div className="top-slider px-4 p-2 bg-white">
              {/* <h5
                className={`fw-bold ${
                  language === "ar" ? "text-end" : "text-start"
                }`}
              >
                {t("newArrivals")}
              </h5> */}
          <h4 className="fw-bold Bestoffers"> {t('hello')}</h4>

            </div>
          </div>
          {/* {console.log(translations[language].newArrivals)} */}

        </div>
      </div>
<div className='container'>

    {
      data?.data.data.offers ? <Slider {...settings}>

        {data?.data.data.offers.map((offer) => <>
          <div className="product shadow  post-slide  pt-0 mt-0 mb-4 border border-2 " key={offer.id}>
          <Favorite prosuctId={offer.id} className='mx-4'/>
          <Link key={offer.id} to={`/ProductDitales/${offer.id}`} className='boxshow'>

            <div className="product-img post-img text-center position-relative d-center flex-column">
           
              <div className="position-relative">
              <div className="container" style={{ maxWidth: '100%' }}>
  <img
    src={offer.image}
    alt=""
    className=""
    height={200}
  />
</div>

               <div className='position-absolute   sliederHover'></div>

                  </div>

              {offer.discount == 0 ? "" : <>
              <div className="bg-bluo text-white px-2 d-inline-block rounded-pill position-absolute title-product bg-danger">{offer.discount}  </div>
              </>}

              {/* <div className="bg-bluo text-white px-2 d-inline-block rounded-pill position-absolute title-product">ORIGINAL USED </div> */}
              {/* <p class="mt-3 text-end px-3 text-50 border-0">{offer.description.split(" ").slice(0, 2).join(' ')}</p> */}
              {/* <p class=" px-3 font-13 cursor-pointer">{offer.details.split(" ").slice(0, 10).join(' ')}</p> */}
            
              <div className="product-details " >
            <div className='d-flex justify-content-between'>
            <div className='mx-3'>
                      {Array(parseInt(offer?.rate)).fill(0).map((_, i) => (
                        <FontAwesomeIcon icon={faStar} key={i} style={{ fontSize: '19px' }} />
                      ))}
                      {Array(5 - parseInt(offer?.rate)).fill(0).map((_, i) => (
                        <FontAwesomeIcon icon={faStar} key={i} style={{ color: "rgb(201 194 189)" }} />
                      ))}
                    </div>
                
              </div>




            </div>
              <h5 className="fw-bold text-end px-4"> {offer.price} SR</h5>
              <div className="d-flex over-product text-start px-3 mt-3 font-9 ">
                {offer.available == 1 ? <>
                 
                </> : <>
                <div className=" w-100">
                {/* <p className="text-green px-2 cursor-pointer bg-danger "> {t("unavailable")}</p> */}
                  <span className="mt-2 "></span>
                </div>
                </>}

              </div>
              <p>{offer.name.split(" ").slice(0, 10).join(' ')}</p>

            </div>
          
           <div className="mb-1 text-center mx-3">
           <button  className='btn btnhover w-100 '>{t('addtocart')} </button>
           </div>
            </Link>
          </div>
         </>)}

      </Slider> : ""
    }
</div>



  </>
}
