import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from "yup";
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext/UserContext';
import { toast } from 'react-toastify';

export default function ForgetPassword() {
  let { BasUrl3 , en} = useContext(UserContext)
  const x = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function handleForgetPasswordSubmit(values) {
    try {
      setIsLoading(true);
      const { data } = await axios.post(`${BasUrl3}password/forget-password${en}`, values);

      console.log(data?.message);

      if (data.status === true) {
        setIsLoading(false);
        x("/ResetPassword"); 
        toast("ioreqhfvn")

      }else{
        console.log("WelcomeAhmed......");
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.error("Server Error:", error.response.data);
        setError(error.response.data.message);
      } else {
        console.error("Error:", error.message);
        setError("An error occurred");
      }
    }
  }

  function validate(values) {
    console.log(values); 
  }

  const validationSchema = yup.object({
    email: yup.string().email().required("required"),
  });

  let formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: handleForgetPasswordSubmit,
  });

  return (
    <div className='w-50 mx-auto py-5'>
      {error && <div className='alert alert-danger'>{error}</div>}

      <form onSubmit={formik.handleSubmit}>
        <h5 className='fw-bold text-center'>Forgot Password</h5>

        <label htmlFor='email' className='mt-2'>Email:</label>
        <input type='email' className='form-control mt-2 ' id="email" onBlur={formik.handleBlur} name='email' value={formik.values.email} onChange={formik.handleChange} />
        {formik.errors.email && formik.touched.email ? <div className='alert alert-danger'>{formik.errors.email}</div> : ""}

        {isLoading ? (
          <button className='btn bg-main btn-add-card mt-2' type='button'>
            <button className='btn bg-color btn-add-card '>  <i className='fas fa-spinner fa-spin'></i></button>
          </button>
        ) : (
          <button disabled={!(formik.isValid && formik.dirty)} className='btn btn-add-card w-100 border-radius p-8' type='submit'>Recover</button>
        )}

        <div className='d-center flex-column'></div>
      </form>
    </div>
  );
}
