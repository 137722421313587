import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from "yup";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext/UserContext';
import { useTranslation } from 'react-i18next';

const Register = () => {
    const { t } = useTranslation();
    const { BasUrl3, en, tokenfirebase, setTokenfirebase } = useContext(UserContext);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [falsedata, setfalsedata] = useState(true);
    const handleRegisterSubmit = async (values) => {
        setIsLoading(true);
        try {
            const { data } = await axios.post(`https://dashboard.cmask.net/api/register${en}`, values);
            if (data.status == true) {
                navigate("/login");
                localStorage.setItem('UserToken', data.data.token);
            }
            setError(data.errors)
            if (data.status === true) {
                navigate("/login");
                localStorage.setItem('UserToken', data.data.token);
            } else {
                setIsLoading(false);
                setfalsedata(data.message);
            }
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                setError(error.response.data.message);
            } else {
                setError("An error occurred");
            }
        }

    };
    const saudiArabianPhoneNumberPattern = /^\+966[0-9]{7}$/;

    // useEffect(() => {
    //     formik.setValues({
    //         ...formik.values,
    //         device_token: tokenfirebase ? tokenfirebase : ''
    //     });
    // }, [tokenfirebase]);
    // useEffect(() => {
    //     if (tokenfirebase) {
    //         formik.setFieldValue('device_token', tokenfirebase);
    //     }
    // }, [tokenfirebase]);
    
    
    const validationSchema = yup.object({
      
        name: yup.string().min(3, 'اسم غير صحيح').max(20, 'اسم غير صحيح').required('يجب إدخال الاسم'),
        email: yup.string().email('البريد الإلكتروني غير صحيح').required('يجب إدخال البريد الإلكتروني'),
        phone: yup.string().matches(saudiArabianPhoneNumberPattern, "Saudi Arabian phone number is not valid").required("Phone number is required"),
        password: yup.string().matches(/^[A-Z][a-z0-9]{5,10}/, 'كلمة المرور غير صحيحة').required('كلمة المرور مطلوبة'),
        password_confirmation: yup.string().oneOf([yup.ref('password')], 'يجب تطابق كلمة المرور').required('يجب إدخال تأكيد كلمة المرور'),
        address: yup.string().min(3, 'العنوان غير صحيح').max(10, 'العنوان غير صحيح').required('يجب إدخال العنوان'),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            password: "",
            password_confirmation: "",
            address: "",
            device_token: "25555",
        },
        validationSchema,
        onSubmit: handleRegisterSubmit
    });

    // Function to set device_token equal to tokenfirebase
 

    return (
     <div className='container'>
           <div className='row'>
            <div className='col-lg-5 col-md-10 mx-auto '>
            {error && <div className='alert alert-danger'>{JSON.stringify(error)}</div>}
                <form onSubmit={formik.handleSubmit}>
                    <h5 className='fw-bold text-center'> {t("createAcount")}</h5>

                    <label htmlFor='name' className='mt-2 mx-2 mx-3'>{t("name")}:</label>
                    <input type='text' placeholder={t("name")} className='form-control mt-2 w-100' id='name' {...formik.getFieldProps('name')} />
                    {formik.touched.name && formik.errors.name && <div className='alert alert-danger'>{formik.errors.name}</div>}

                    <label htmlFor='email' className='mt-2 mx-3'> {t("mail")}:</label>
                    <input type='email' placeholder={t("mail")} className='form-control mt-2 ' id='email' {...formik.getFieldProps('email')} />
                    {formik.touched.email && formik.errors.email && <div className='alert alert-danger'>{formik.errors.email}</div>}

                    <label htmlFor='phone' className='mt-2 mx-3' pla> {t("phone")}:</label>
                    <input
                        type='tel'
                        placeholder= {t("phone")}
                        className='form-control mt-2 w-100 d-r'
                        id='phone'
                        {...formik.getFieldProps('phone')}
                        onChange={(e) => {
                            let phoneNumber = e.target.value;
                            phoneNumber = phoneNumber.replace(/\D/g, '');
                            phoneNumber = '+' + phoneNumber;
                            formik.setFieldValue('phone', phoneNumber);
                        }}
                    />
                    {formik.touched.phone && formik.errors.phone && <div className='alert alert-danger'>{formik.errors.phone}</div>}

                    <label htmlFor='password' className='mt-2 mx-3' > {t("password")}:</label>
                    <input type='password' placeholder={t("password")} className='form-control mt-2 ' id='password' {...formik.getFieldProps('password')} />
                    {formik.touched.password && formik.errors.password && <div className='alert alert-danger'>{formik.errors.password}</div>}

                    <label htmlFor='password_confirmation' className='mt-2 mx-3'>  {t("passwordconfirmation")} :</label>
                    <input type='password' className='form-control mt-2 ' placeholder={t("passwordconfirmation")} id='password_confirmation' {...formik.getFieldProps('password_confirmation')} />
                    {formik.touched.password_confirmation && formik.errors.password_confirmation && <div className='alert alert-danger'>{formik.errors.password_confirmation}</div>}

                    <label htmlFor='address' className='mt-2 mx-3'>{t("adresse")}:</label>
                    <input type='text' className='form-control mt-2 ' placeholder={t("adresse")} id='address' {...formik.getFieldProps('address')} />
                    {formik.touched.address && formik.errors.address && <div className='alert alert-danger'>{formik.errors.address}</div>}

                    {isLoading ? (
                        <button className='btn bg-main btn-add-card mt-2' type='button'><i className='fas fa-spinner fa-spin'></i> </button>
                    ) : (
                        <button 
   disabled={isLoading || !formik.isValid || !formik.dirty}

    className='btn btn-add-card w-100 border-radius p-8 mt-2' 
    type='submit'
>
    {t("loginaount")}
</button>
                    )}

                    <p className='mt-3'>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
                    <div className='d-center flex-column'>
                        <div className='d-flex'>
                            <p className='mx-3 mt-3'>Already have an account?</p>
                            <Link to='/Login' className='mt-3'>Login here</Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
     </div>
    );
}

export default Register;
