import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import SliderProductDetalis from './SliderProductDetails';
import ProductSlider from '../CatogrySlider/ProductSlider';
import { useContext } from 'react';
import { UserContext } from '../UserContext/UserContext';
import { CartContext } from '../Cart/CartContext';
import { toast } from 'react-toastify';
import { BallTriangle } from 'react-loader-spinner';
import { Button, Collapse } from 'react-bootstrap'; import Ratings from '../Ratings/Ratings';
import ProductReview from '../Ratings/product-review';
import GetRevei from '../Ratings/GetRevie';
import { useTranslation } from 'react-i18next';
import ProductDitalesSlder from '../Home/ProductDitalesSlder';


export default function ProductDetails({ }) {
  const [open, setOpen] = React.useState(false);
  let { addToCart } = useContext(CartContext)
  let { BasUrl3 , en } = useContext(UserContext)
  const [firstLoad, setFirstLoad] = useState(true);

  
  async function addProduct(ProductId) {
    try {
      let response = await addToCart(ProductId); // تنفيذ إضافة المنتج إلى السلة
      if (response?.status === true)
        // console.log(response?.status, "statusstatusstatusstatusstatusstatusstatus");
        toast(data.message, {
          position: "top-center"
        })
    } catch (error) {
      console.log('Error adding product to cart', error);
    }

    const token = localStorage.getItem('UserToken');

  }
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(5 - value);
  const [data1, setdate] = useState();

  let params = useParams();
  const [tokenExists, setTokenExists] = useState(false);
  const checkToken = () => {
    const token = localStorage.getItem('UserToken')                                                     
    if (token) {
      setTokenExists(true);
    }
  };
  useEffect(() => {
    checkToken(); // Check token existence when component mounts
  }, []);
  const gitProductDetails = async (id) => {
    try {
      let config = {};
      const token = localStorage.getItem('UserToken');
      if (token) {
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      }
      const response = await axios.post(`${BasUrl3}products/show/${id}${en}`, config);
      setdate(response.data);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch product details');
    }
  };
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    // lazyLoad: true,
    slidesToScroll: 1,


    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const { isError, isLoading, data } = useQuery(['productDetails', params.id], () => gitProductDetails(params.id));
  useEffect(() => {
    async function futch() {
      if (data?.data?.product) {
        setValue(parseInt(data?.data?.product?.rate));
        setValue2(5 - parseInt(data?.data?.product?.rate));
      }
    }
    futch();
  }, [data]);


useEffect(() => {
  // التمرير إلى أعلى الصفحة عند تحميل الصفحة
  window.scrollTo(0, 0);
  
}, []);

const currentLocation = useLocation();

useEffect(() => {
  // التمرير إلى أعلى الصفحة عند تحميل الصفحة
  window.scrollTo(0, 0);

  // إعادة تحميل الصفحة فقط عند الدخول لأول مرة
  if (firstLoad) {
    setFirstLoad(false);
    // القيام بالتحميل الأولي هنا (مثلا، جلب البيانات الأولية)
  }
}, [currentLocation, firstLoad]);

  
  return (<div id="container">
    <div className='mt-4 container' >
      {data?.data?.product ? <div className='row'>
    <div className="col-1"></div>

    <div className='col-sm-4'>
    <div className='w-100 d-center'>
        <img src={data?.data?.product?.image} className='img-detailes' style={{ maxWidth: '100%', maxHeight: '100%' }} />
    </div>
</div>

    <div className="col-1"></div>

        <div className='col-sm-6'>
<div className="mx-5">
<h3 className='fw-bold'>{data?.data?.product?.name?.split(" ").slice(0, 5).join('  ')}</h3>
          <h3 className='fw-bold mt-3'>{data?.data?.product?.details.split(" ").slice(0, 5).join('  ')}</h3>
          <div className='d-flex justify-content-between'>
            <div className='d-flex justify-content-between'>
              <div className='mx-3'>
                {
                  Array(value)
                    .fill(0)
                    .map((_, i) => (
                      <FontAwesomeIcon
                        icon={faStar}
                        key={i}
                        style={{ fontSize: '15px' }}
                      />))
                }
                {
                  Array(value2)
                    .fill(0)
                    .map((_, i) => (
                      <FontAwesomeIcon
                        icon={faStar}
                        key={i}
                        style={{ color: "#f2f0ee" }}
                      />
                    ))
                }
              </div>
            </div>
            <div>
              {/* <div className="d-flex justify-content-around ">
                <span className="icone-footer d-center mx-1"><Link to="icone-footer" className="text-white fs-5"><i
                  className="fa-brands fa-instagram"></i></Link></span>
                <span className="icone-footer d-center mx-1"><Link to="icone-footer" className="text-white fs-5"><i
                  className="fa-brands fa-facebook-f"></i></Link></span>
                <span className="icone-footer d-center mx-1"><Link to="icone-footer" className="text-white fs-5"><i
                  className="fa-brands fa-linkedin-in"></i></Link></span>
                <span className="icone-footer d-center mx-1"><Link to="icone-footer" className="text-white fs-5"><i
                  className="fa-brands fa-whatsapp"></i></Link></span>
              </div> */}
                  <div className="d-flex over-product text-start px-3 mt-3 ">
              {data?.data?.product?.available == 1 ? <>
            
              </> : <>
                <p className="text-green px-2 cursor-pointer font-12 bg-danger"> {t('unavailable')}</p>
                <span className="mt-2 "></span>
              </>}
            </div>
            </div>
          </div>
          <div className='border-solid w-100 mt-2'></div>
          <h5 className='fw-bold mx-4 mt-4'> :{data?.data?.product?.details.split(" ").slice(0, 5).join('  ')}</h5>
          <div className='mt-4'>
            <div className='d-flex'>
              <p> {t('price')} </p>
              <h5 className='fw-bold mx-4'> {data?.data?.product?.price} SR</h5>
            </div>
       
            {localStorage.getItem('UserToken') !== null ? <>
              <button onClick={() => addProduct(params.id)} className='btn btn-add-card w-100'>{t('addtocart')} </button>

            </> : <>
              <Link to={"/cart"}>
                <button className='btn btn-add-card w-100'>   {t('addtocart')}   </button>
              </Link>
            </>}
            <div className='mt-5 d-flex justify-content-between'>
              <div >
                {localStorage.getItem('UserToken') !== null ? <>
                  <Button className='btn btn-add-card'
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                  >
                   {t('addrating')}
                  </Button>
                </> : <Link to={"/Login"}>
                  <Button className='btn btn-add-card'>
                  {t('addrating')}
                  </Button>
                </Link>}
              </div>
              <div >
                <h5 className='fw-bold'>     {t('customerreviews')}     </h5>
                {/* <p className='mt-2'>لا يوجد تقييمات, 1 بعد</p> */}
              </div>
            </div>
            <Collapse in={open}>
              <div id="example-collapse-text">
                <Ratings propsId={params.id}></Ratings>
        
              </div>
            </Collapse>
          </div>
</div>
        </div>

      </div> : <div className='d-flex justify-content-center align-items-center' style={{ height: '80vh' }}>
        <BallTriangle
          height={100}
          width={100}
          radius={5}
          color="#4fa94d"
          ariaLabel="ball-triangle-loading"
          wrapperClass={{}}
          wrapperStyle=""
          visible={true}
        />
      </div>}
    </div>
    <div className='container'>

    <ProductDitalesSlder></ProductDitalesSlder>
    </div>
    <div className='mt-4'>
  <h5 className='text-center'> {t('ratings')}</h5>
  <div className='mt-4 container'>
    <GetRevei pro={params?.id}></GetRevei>
  </div>
</div>
  </div>
  
  );
}