import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Favorite from './FavoriteAdd';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import DelateFaforrit from './DelateFaforute';
import { UserContext } from '../Components/UserContext/UserContext';
import { useTranslation } from 'react-i18next';

export default function GetFavorite() {
    const [orderData, setOrderData] = useState([]);
    const [value, setValue] = useState(5);
    const [value2, setValue2] = useState(0);
    let { BasUrl3 , en} = useContext(UserContext)
  const { t } = useTranslation();
    
    useEffect(() => {
        async function fetchOrderData() {
            try {
                const token = localStorage.getItem('UserToken');
                const response = await axios.get(`${BasUrl3}favourites${en}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },

                });
                // console.log(response?.data?.data);

                setOrderData(response.data?.data || []); // تعيين قائمة الطلبات إلى الحالة الداخلية
            } catch (error) {
                console.error('حدث خطأ:', error);
            }
        }
        fetchOrderData();
    }, []);
    
    useEffect(() => {
        if (orderData && Array.isArray(orderData) && orderData.length > 0) {
          const product = orderData[0]; // Consider the first item or adjust logic to handle multiple items
          const rating = parseFloat(product.rate); // Parse rating to float or integer based on data
    
          // Ensure rating is within a valid range and handle accordingly
          if (!isNaN(rating) && rating >= 0 && rating <= 5) {
            setValue(Math.floor(rating)); // Round down to the nearest integer
            setValue2(Math.floor(5 - rating)); // Calculate remaining stars
          } else {
            // Default values in case of invalid or missing rating
            setValue(0);
            setValue2(5);
          }
        }
      }, [orderData]);
    return <>
        {
            orderData.length > 0 ? <div className='row mt-5'>

                {orderData.map((offer) => <>
                    <div className='col-3 '>
                        <div className=''>
                            <div className=''>
                                <div className="product shadow  post-slide  pt-0 mt-0 mb-4" key={offer.id}>
                                  
                                    <div className='d-flex justify-content-between mx-4 pt-2'>
                                    <Favorite prosuctId={offer.id} className='mx-4' />
                                    <div><DelateFaforrit ptoductId={offer.id}/></div>
                                    </div>
                                    <Link key={offer.id} to={`/ProductDitales/${offer.id}`}>


                                        <div className="product-img post-img text-center position-relative">
                                            <img src={offer.image} alt="" className="w-100     " height={300} />

                                            {offer.discount == 0 ? "" : <>
                                                <div className="bg-bluo text-white px-2 d-inline-block rounded-pill position-absolute title-product bg-danger">{offer.discount}  </div>
                                            </>}

                                            <h5 className="fw-bold text-end px-4"> {offer.price} EGP</h5>
                                            <p>{offer.name.split(" ").slice(0, 10).join(' ')}</p>

                                        </div>
                                        <div className="product-details " >
                                            <div className='d-flex justify-content-between'>
                                            <div className='mx-3'>
                {/* Render rating stars */}
                {Array(value).fill(0).map((_, i) => (
                  <FontAwesomeIcon icon={faStar} key={i} style={{ fontSize: '15px' }} />
                ))}
                {Array(value2).fill(0).map((_, i) => (
                  <FontAwesomeIcon icon={faStar} key={i} style={{ color: '#f2f0ee' }} />
                ))}
              </div>

                                            </div>

                                            <div className="d-flex over-product text-start px-3 mt-3 ">
                                                {offer.available == 1 ? <>
                                                    <p className="text-green px-2 cursor-pointer">{t('available')}</p>
                                                    <span className="mt-2 "></span>
                                                </> : <>
                                                    <p className=" px-2 cursor-pointer "> {t('unavailable')}</p>
                                                    <span className="mt-2 "></span>
                                                </>}
                                             
                                            </div>



                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>)}

            </div> : ""
        }

    </>
}
