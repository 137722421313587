import axios from "axios";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import ProductRating from "./ReturnStar";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../UserContext/UserContext";
import Favorite from "../../Favorite/FavoriteAdd";
import translations from "../Lang/translations";
import { useTranslation } from "react-i18next";
// import { useTranslation } from 'react-i18next';


export default function ProductSlider() {
  let { language, en } = useContext(UserContext);

  const { t } = useTranslation();


  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    // lazyLoad: true,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  function getCatogry() {
    return axios.post(`https://dashboard.cmask.net/api/home${en}`);
  }
  useEffect(() => {
    // التمرير إلى أعلى الصفحة عند تحميل الصفحة
    window.scrollTo(0, 0);
  }, []);
  
  let { isError, data, isLoading } = useQuery("catogrySliderr", getCatogry);
  useEffect(() => {
    async function futch() {
      if (data?.data?.data.products) {
        data?.data?.data.products.map((product) => {
          const rate = parseInt(product?.rate);
          if (!isNaN(rate)) {
            setValue(parseInt(rate));
            setValue2(rate ? 5 - rate : 0); // استخدام القيمة المحولة لحساب value2 وتقريبها إلى أقرب عدد صحيح
          }
        });
      }
    }

    futch();
  }, [data]);

  // --------------------------

  return <div className="bg-white container mt-6 pt-0 " id="container">
   <div className="top-slider px-4 mt-0 pt-0  bg-white mt-300  container">
        <div className="d-flex justify-content-between align-items-center">
         

          <h4 className="fw-bold Bestoffers"> {t('mostwatched')}</h4>
        </div>
      </div>
    <div className="container ">
     

      {data?.data?.data.products ? (
        <Slider {...settings}>
          {data?.data?.data.products.map((product) => (
            <div className="boxshow">
              <div
                className="product shadow post-slide border border-2 pt-0 mt-0 h-100"
                key={product.id}
              >
                <Favorite prosuctId={product.id} className="mx-4 " />
                <Link to={`/ProductDitales/${product.id}`} key={product.id}>
                  <div className="product-img post-img text-center position-relative d-center flex-column">
                  <div className="position-relative" style={{ maxWidth: '100%' }}>
                  <img
                      src={product.image}
                      alt=""
                      className=""
                      height={200}
                    />
               <div className='position-absolute   sliederHover'></div>

                  </div>

                    <div className="d-flex justify-content-between">
                      <div className="mx-3">
                        {Array(parseInt(product?.rate))
                          .fill(0)
                          .map((_, i) => (
                            <FontAwesomeIcon
                              icon={faStar}
                              key={i}
                              style={{ fontSize: "19px" }}
                            />
                          ))}
                        {Array(5 - parseInt(product?.rate))
                          .fill(0)
                          .map((_, i) => (
                            <FontAwesomeIcon
                              icon={faStar}
                              key={i}
                              style={{ color: "rgb(201 194 189)" }}
                            />
                          ))}
                      </div>
                    </div>
                <div className="d-flex justify-content-between">
                <h5 className="fw-bold text-end px-4 ">  {product.price} SR     </h5>
                    
                <div className="d-flex over-product text-start px-3 mt-3 font-9 ">
                {product.available == 1 ? <>
                 
                </> : <>
                <div className=" w-100">
                {/* <p className="text-green px-2 cursor-pointer bg-danger "> {t("unavailable")}</p> */}
                  <span className="mt-2 "></span>
                </div>
                </>}

              </div>
             
                </div>
                <div className="pb-1 mx-2">
                <p >{product.name.split(" ").slice(0, 5).join("  ")}</p>
                </div>
                  </div>
                  <div className="product-details ">
              
                  </div>
                 <div className="mx-3 mb-1">
                 <button  className='btn btnhover w-100'>{t('addtocart')} </button>
                 </div>

                </Link>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        ""
      )}
    </div>
    </div>
}
