// import axios from "axios";
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserContext } from '../UserContext/UserContext';

export default function Shipping() {
    const [orderData, setOrderData] = useState([]);
    let { BasUrl3 , en} = useContext(UserContext)

    useEffect(() => {
        async function fetchOrderData() {
            try {
                const token = localStorage.getItem('UserToken');
                const response = await axios.get(`${BasUrl3}order/shipping${en}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setOrderData(response.data?.data || []);
                // console.log(orderData.length , "orderData.lengthorderData.lengthorderData.length");
            } catch (error) {
                console.error('حدث خطأ:', error);
                setOrderData([]); // تعيين قائمة فارغة في حالة حدوث خطأ
            }
        }

        fetchOrderData();
    }, []);

    return (
        <div>
            {Array.isArray(orderData) && orderData.length > 0 ? (
                <div className='row'>
                    {orderData.map((order) => (
                        <div key={order.id} className='col-4 mb-3'>
                            <div className='boxshow p-4'>
                                <p>Address: <span className='text-dark fw-bold'>{order.address}</span></p>
                                <p>City: <span className='text-dark fw-bold'>{order.city}</span></p>
                                <p>Date: <span className='text-dark fw-bold'>{order.order_date}</span></p>
                                <p>Total Amount: <span className='text-dark fw-bold'>{order.total_amount}</span></p>
                                <p>Total Price: <span className='text-dark fw-bold'>{order.total_price}</span></p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p className='mx-auto text-center mt-5 fw-bold'>لا توجد بيانات لعرضها حاليًا</p>
            )}
        </div>
    );
}


