import axios from 'axios'
import React from 'react'
import { useContext } from 'react';
import { useQuery } from 'react-query'

import Slider from "react-slick";
import { UserContext } from '../UserContext/UserContext';
import { Link } from 'react-router-dom';
import { BallTriangle } from 'react-loader-spinner';


export default function SliderCatogry() {
  let { BasUrl3 , en} = useContext(UserContext)


  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  function getCatogry() {
    return axios.post(`${BasUrl3}home${en}`)
  }

  let { isError, data, isLoading } = useQuery("SliderCcvatogry", getCatogry)
  // console.log(data?.data?.data.categories.status , 'solider');



  return <>



    {

      <div className='slider-cartCatogry  container mt-300  '>
        {
          data?.data.data.categories_slider ? <Slider {...settings} >
            {data?.data.data.categories_slider.map((ategorie) =>
            <div  className='mt-0 pt-0 d-flex flex-column post-slide  '>
      <Link to={`/ProductDitales/${ategorie.id}`}  className='mx-2 border border-2  '>
         
         <div className="position-relative mx-3 d-center flex-column">
         <img key={ategorie.id} src={ategorie.image_name}  className='w-100 border50'/>

           <div className='position-absolute   sliederHover border50'>
           <p className='mt-3 fw-bold d-center text-center font-12 mt-5 text-white'>{ategorie?.name} </p>
           </div>
           

              </div>
              <p className=' fw-bold d-center text-center font-12  text-dangar'>{ategorie?.name} </p>

     
          </Link>
            </div>
       
              )}


          </Slider> :   <div className='d-flex justify-content-center align-items-center ' style={{ height: '80vh' }}>
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#4fa94d"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                    />
                </div>
        }
      </div>
    }






  </>
}
