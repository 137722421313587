import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../UserContext/UserContext';
import Favorite from '../../Favorite/FavoriteAdd';
import { useTranslation } from 'react-i18next';

const SearchAPI = () => {
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { BasUrl3, en } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const name = params.get('name');
    if (name) {
      handleSearch(name);
    }
  }, [location.search]);

  const handleSearch = async (searchQuery) => {
    setIsLoading(true);
    console.log(searchQuery);

    try {
      const response = await axios.post(`${BasUrl3}products/search${en}`, {
        name: searchQuery,
      });
      setSearchResults(response.data?.data || []);
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchResults && Array.isArray(searchResults) && searchResults.length > 0) {
      const firstProduct = searchResults[0];
      const rate = parseInt(firstProduct?.rate);
      setValue(rate);
      setValue2(rate ? 5 - rate : 0);
    }
  }, [searchResults]);

  return (
    <div className='container'>
      {/* <div className='row'>
        <div className='col-12'>
          <input
            placeholder='Search...'
            name='name'
            value={formik.values.name}
            className='form-control'
            type='text'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
      </div> */}

      <div className='row'>
        {isLoading ? (
          <div className='col-sm-12 d-center'>
            <BallTriangle
              height={100}
              width={100}
              radius={5}
              color='#4fa94d'
              ariaLabel='ball-triangle-loading'
            />
          </div>
        ) : !searchResults || !Array.isArray(searchResults) ? (
          <h1 className='text-danger'>No data available</h1>
        ) : (
          searchResults.map((imgAds) => (
            <div className='col-lg-3 mt-2' key={imgAds.id}>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-lg-12 border border-2'>
                    <Favorite prosuctId={imgAds.id} className='mx-4' />
                    <Link to={`/ProductDitales/${imgAds.id}`} className='boxshow'>
                      <div className='post-slide pt-0 mt-0 w-100' key={imgAds.id}>
                        <div className='product-img img text-center position-relative'>
                          <div className='d-center w-100'>
                            <img
                              src={imgAds.image}
                              className=''
                              style={{ maxWidth: '80%', maxHeight: '70%' }}
                            />
                          </div>
                          <div className='d-flex justify-content-between'>
                            <div className='mx-3'>
                              {Array(parseInt(imgAds.rate)).fill(0).map((_, i) => (
                                <FontAwesomeIcon
                                  icon={faStar}
                                  key={i}
                                  style={{ fontSize: '15px' }}
                                />
                              ))}
                              {Array(parseInt(5 - imgAds.rate)).fill(0).map((_, i) => (
                                <FontAwesomeIcon
                                  icon={faStar}
                                  key={i}
                                  style={{ color: '#f2f0ee' }}
                                />
                              ))}
                            </div>
                          </div>
                          <h5 className='fw-bold text-end px-4'> {imgAds.price} SR</h5>
                          <p>{imgAds.name.split(' ').slice(0, 5).join('  ')}</p>
                        </div>
                        <div className='product-details'>
                          <div className='d-flex over-product text-start px-3 mt-3'>
                            {imgAds.available ? (
                              <p className='text-green px-2 cursor-pointer font-12'>متوفر</p>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='mb-1 w-100 text-center'>
                        <button className='btn btnhover w-100 '>{t('addtocart')} </button>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SearchAPI;
