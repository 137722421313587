import React, { useContext, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { UserContext } from '../Components/UserContext/UserContext';
import { Link } from 'react-router-dom';

const Favorite = ({prosuctId}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  let { BasUrl3 , en} = useContext(UserContext)
  const token = localStorage.getItem('UserToken');
  const handleAddToFavorites = async () => {     
    setLoading(true);
    

    try {
      const token = localStorage.getItem('UserToken');
      if (!token) {
        throw new Error('User is not authenticated');
      }

      const response = await axios.post(
        `${BasUrl3}favourites/${prosuctId}${en}`, // استبدل هذا برابط API الفعلي الخاص بك
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    
      );
      if(response?.data?.status == true ){
        toast.success(response?.data?.message, {
            position: 'top-left'
          })
      }

      setLoading(false);
      console.log('Item added to favorites:', response?.data);
    } catch (error) {
      setLoading(false);
      setError(' Failed to add item to favorites');
      console.error('Error adding item to favorites:', error);
    }
  };

  return (
    <div>
      {/* عرض عناصر واجهة المستخدم الخاصة بك */}
      {/* على سبيل المثال، زر لإضافة العنصر إلى المفضلة */}
      {
        token == null ? <> 
        <Link to={"/Login"} className='mx-3 pt-2'><i class="fa-regular fa-heart"></i> </Link>
         </> :     <button onClick={handleAddToFavorites} disabled={loading} className='btn border'>
        {loading ? <i class="fa-solid fa-heart"></i> : <i class="fa-regular fa-heart"></i>  }
      </button>
      }
 
      {error && <div>{error}</div>}
    </div>
  );
};

export default Favorite;
