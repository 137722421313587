import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../UserContext/UserContext';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

export default function Countries() {
    let { setUserToken, UserToken, BasUrl, BasUrl3, counterCart, en } = useContext(UserContext)
    const token = localStorage.getItem('UserToken');


    const [settings, setSettings] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        async function fetchSettings() {
            try {
                const response = await axios.get(`https://dashboard.cmask.net/api/countries${en}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setSettings(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        }

        fetchSettings();
    }, []);



    return <>
        {settings?.data.map((setting) => <>

            <div className='container mt-5'>
                <div className='row'>
                    <Link to={`/Cities/${setting.id}`}>
                        <div className='col-4'>
                            <div className='bg-light border-r'>
                                <div className='pt-3'>
                                    <div className='pt-2 mx-3 pb-2 d-flex'>    <p className='fw-bold mx-2'>name</p> {setting.name}</div>
                                    <div className='pt-2 mx-3 pb-2 d-flex'>   <p className='fw-bold mx-2'>code Country</p> {setting.country_ref_code}</div>
                                    <div className='pt-2 mx-3 pb-2 d-flex'>    <p className='fw-bold mx-2'>code Phone</p> {setting.phone_prefix}</div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

        
        </>)}
    </>
}
