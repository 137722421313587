import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from "yup";
import axios from 'axios';
import { UserContext } from '../UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ChangePassword() {
    let { BasUrl3 , en} = useContext(UserContext)
    // استخدام useContext للوصول إلى الحالة والدوال من UserContext
    let { setUserToken, setUserData } = useContext(UserContext);
    const { t } = useTranslation();

    // useState لإدارة حالة النجاح في التحديث والأخطاء والتحميل
    let [updateSuccess, setUpdateSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Hook من React Router DOM للانتقال بين الصفحات
    let navigate = useNavigate();

    // دالة تنفيذ عملية التحديث عبر الطلب الى السيرفر
    async function handleLoginSubmit(values) {
        try {
            // الحصول على رمز الوصول من التخزين المحلي
            const token = localStorage.getItem('UserToken');

            // تعيين قيمة التحميل إلى true
            setIsLoading(true);

            // إجراء طلب PUT لتحديث ملف التعريف الشخصي
            const { data } = await axios.put(
                `${BasUrl3}profile/change-password${en}`,
                values, // قيم الحقول من النموذج
                {
                    headers: {
                        Authorization: `Bearer ${token}` // إرسال رمز الوصول
                    }
                }
            );
            console.log(data);

            // التحقق من نجاح الطلب
            if (data.status === true) {
                // إذا نجح، قم بتحديث الحالة والمعلومات المستلمة
                setIsLoading(false);
                setUserToken(data.data.token);
                setUserData(data);
                setUpdateSuccess(true); // تعيين حالة النجاح إلى true
            }
        } catch (error) {
            // التعامل مع الأخطاء في حالة فشل الطلب
            setIsLoading(false);
            if (error.response) {
                setError(error.response.data.message); // رسالة الخطأ من الاستجابة
            } else {
                setError("An error occurred"); // رسالة خطأ عامة
            }
        }
    }

    // نموذج Yup schema للتحقق من الحقول المدخلة
    const egyptianPhoneNumberPattern = /^(\+201|01|00201)[0-2,5]{1}[0-9]{8}/;
    let validationSchema = yup.object({
        old_password: yup.string().required("Name is required"),
        password: yup.string().matches(/^[A-Z][a-z0-9]{5,10}/, "Password is not valid ").required("Password is required"),
        password_confirmation: yup.string().oneOf([yup.ref("password")], "Passwords must match").required("Password confirmation is required"),
    });

    // useFormik hook لإدارة الحالة والتحقق من النموذج
    let formik = useFormik({
        initialValues: {
            old_password: "",
            password: "",
            password_confirmation: "",
        
        },
        validationSchema,
        onSubmit: handleLoginSubmit // دالة التقديم
    });

    return (
        <div className='w-50 mx-auto py-5'>
            {updateSuccess && (
                <div className='alert alert-success'>{t('Profilesuccessfully')}  </div>
            )}

            {error && <div className='alert alert-danger'>{error}</div>}

            <form onSubmit={formik.handleSubmit}>
                <h5 className='fw-bold text-center'> {t('ChangePassword')}  </h5>

                {/* حقول الإدخال */}
                <label htmlFor='old_password' className='mt-2'>{t('oldpassword')} :</label>
                <input type='password' className='form-control mt-2' id="old_password" onBlur={formik.handleBlur} name='old_password' value={formik.values.old_password} onChange={formik.handleChange} />
                {formik.errors.old_password && formik.touched.old_password && <div className='alert alert-danger'>{formik.errors.old_password}</div>}

                <label htmlFor='password' className='mt-2'>{t('password')} :</label>
                <input type='tel' className='form-control mt-2 d-rtl' id="phone" onBlur={formik.handleBlur} name='password' value={formik.values.password} onChange={formik.handleChange} />
                {formik.errors.password && formik.touched.password && <div className='alert alert-danger'>{formik.errors.password}</div>}

                <label htmlFor='password_confirmation' className='mt-2'>{t('passwordconfirmation')} :</label>
                <input type='text' className='form-control mt-2' id="password_confirmation" onBlur={formik.handleBlur} name='password_confirmation' value={formik.values.password_confirmation} onChange={formik.handleChange} />
                {formik.errors.password_confirmation && formik.touched.password_confirmation && <div className='alert alert-danger'>{formik.errors.password_confirmation}</div>}

                {/* زر التحديث */}
                {isLoading ? (
                    <button className='btn bg-main btn-add-card mt-2 p-0 m-0' type="button">
                        <button className='btn bg-color btn-add-card p-2 m-0'>  <i className="fas fa-spinner fa-spin"></i></button>
                    </button>
                ) : (
                    <button disabled={!(formik.isValid && formik.dirty)} className='btn btn-add-card w-100 border-radius p-8' type="submit">{t('UpdateProfile')}  </button>
                )}

                {/* نص إشارة الموقع */}
                {/* <p className='mt-4'>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply</p> */}
            </form>
        </div>
    );
}
