import React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function DeleteAll() {
  const handleDelete = async () => {
    let token = localStorage.getItem("UserToken");
    console.log("Token", token);

    try {
      const response = await axios.post(
        `https://dashboard.cmask.net/api/notifications/deleteAll`,
        null, // pass null as the second argument since you're not sending any data in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log(response, 'Notification deleted successfully');
      if (response.data.status === true) {
        toast.success(response.data.message);
      }
      if (response.data.status === false) {
        toast.error(response.data.message);
      }
      console.log('Deleted Notification:', response.data);
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  return (
    <div>
      <button onClick={handleDelete} className='btn d-flex'>
        <i className="fa fa-trash text-danger mt-1" aria-hidden="true"></i>
        <p className='mx-2 text-danger font-15'>Delete all</p>
      </button>
    </div>
  );
}
