import axios from 'axios'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../UserContext/UserContext';
import { BallTriangle } from 'react-loader-spinner';
export default function ProductReview({pro}) {
  let { BasUrl3 , en} = useContext(UserContext)
  const [value, setValue] = useState(5)
  const [value2, setValue2] = useState(5-value)
  const token = localStorage.getItem('UserToken'); // استرجاع الـ token من localStorage أو أي مكان آخر تخزن فيه الـ token
  function getCatogry(token) {
    return axios.get(`${BasUrl3}product-review/${pro}${en}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  let { isError, data, isLoading } = useQuery("catogrySliderr", () => getCatogry(token));
  useEffect(() =>{
    async function futch() {
      if (Array.isArray(data?.data?.data)) {
        data?.data?.data?.map((product) => {
          console.log(product?.rate ,'prodcutmikolkl74854' );
          setValue(product?.rate);
          setValue2(5 - product?.rate)
        })
      }
    }
  futch();
  }, [data])
  return (
    <>
      {
        data?.data?.data ? (
          <div className='container mt-5'>
            <div className='row'>
              {Array.isArray(data?.data?.data) && data?.data?.data?.map((product) =>
                <div className='col-md-3 pt-3' key={product?.id}>
                  <div className="pt-0 mt-0 ">
                    <div className="">
                      <div className='d-flex justify-content-between pt-3'>
                        <div className='mx-3'>
                          {
                            Array(value)
                              .fill(0)
                              .map((_, i) => (
                                <FontAwesomeIcon
                                  icon={faStar}
                                  key={i}
                                  style={{ fontSize: '15px' }}
                                />))
                          }
                          {
                            Array(value2)
                              .fill(0)
                              .map((_, i) => (
                                <FontAwesomeIcon
                                  icon={faStar}
                                  key={i}
                                  style={{ color: "#f2f0ee" }}
                                />))
                          }
                        </div>
                      </div>
                      <h5 className="fw-bold text-start mt-4 px-4 d-flex">name: <h4 className='mx-2'>{product?.user_name}</h4></h5>
                      <p className='pb-5 m-auto text-start px-4'><h5 className='fw-bold pb-3'>comment:</h5> {product?.comment} </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : <div className='d-flex justify-content-center align-items-center' style={{ height: '80vh' }}>
        <BallTriangle 
          height={100}
          width={100}
          radius={5}
          color="#4fa94d"
          ariaLabel="ball-triangle-loading"
          wrapperClass={{}}
          wrapperStyle=""
          visible={true}
        />
      </div>
      }
    </>
  );
  
}
