import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from "yup";
import axios from 'axios';
import { UserContext } from '../UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function UpdateProfile() {
    let { BasUrl3 , en} = useContext(UserContext)
    // استخدام useContext للوصول إلى الحالة والدوال من UserContext
    let { setUserToken, setUserData } = useContext(UserContext);
    const { t } = useTranslation();
    // useState لإدارة حالة النجاح في التحديث والأخطاء والتحميل
    let [updateSuccess, setUpdateSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Hook من React Router DOM للانتقال بين الصفحات
    let navigate = useNavigate();

    // دالة تنفيذ عملية التحديث عبر الطلب الى السيرفر
    async function handleLoginSubmit(values) {
        try {
            // الحصول على رمز الوصول من التخزين المحلي
            const token = localStorage.getItem('UserToken');

            // تعيين قيمة التحميل إلى true
            setIsLoading(true);

            // إجراء طلب PUT لتحديث ملف التعريف الشخصي
            const { data } = await axios.put(
                `${BasUrl3}profile/update${en}`,
                values, // قيم الحقول من النموذج
                {
                    headers: {
                        Authorization: `Bearer ${token}` // إرسال رمز الوصول
                    }
                }
            );

            // التحقق من نجاح الطلب
            if (data.status === true) {
                // إذا نجح، قم بتحديث الحالة والمعلومات المستلمة
                setIsLoading(false);
                setUserToken(data.data.token);
                setUserData(data);
                setUpdateSuccess(true); // تعيين حالة النجاح إلى true
            }
        } catch (error) {
            // التعامل مع الأخطاء في حالة فشل الطلب
            setIsLoading(false);
            if (error.response) {
                setError(error.response.data.message); // رسالة الخطأ من الاستجابة
            } else {
                setError("An error occurred"); // رسالة خطأ عامة
            }
        }
    }

    // نموذج Yup schema للتحقق من الحقول المدخلة
    const egyptianPhoneNumberPattern = /^(\+201|01|00201)[0-2,5]{1}[0-9]{8}/;
    let validationSchema = yup.object({
        name: yup.string().required("Name is required"),
        phone: yup.string().matches(egyptianPhoneNumberPattern, "Phone number is not valid").required("Phone number is required"),
        address: yup.string().required("Address is required"),
    });

    // useFormik hook لإدارة الحالة والتحقق من النموذج
    let formik = useFormik({
        initialValues: {
            name: "",
            phone: "",
            image: "",
            address: "",
        },
        validationSchema,
        onSubmit: handleLoginSubmit // دالة التقديم
    });

    return (
        <div className='w-50 mx-auto py-5'>
            {updateSuccess && (
                <div className='alert alert-success'>{t('Profilesuccessfully')}</div>
            )}

            {error && <div className='alert alert-danger'>{error}</div>}

            <form onSubmit={formik.handleSubmit}>
                <h5 className='fw-bold text-center'>{t('update')}</h5>

                {/* حقول الإدخال */}
                <label htmlFor='name' className='mt-2'>{t('name')}:</label>
                <input type='text' className='form-control mt-2' id="name" onBlur={formik.handleBlur} name='name' value={formik.values.name} onChange={formik.handleChange} />
                {formik.errors.name && formik.touched.name && <div className='alert alert-danger'>{formik.errors.name}</div>}

                <label htmlFor='phone' className='mt-2'>{t('phone')}:</label>
                <input type='tel' className='form-control mt-2' id="phone" onBlur={formik.handleBlur} name='phone' value={formik.values.phone} onChange={formik.handleChange} />
                {formik.errors.phone && formik.touched.phone && <div className='alert alert-danger'>{formik.errors.phone}</div>}

                <label htmlFor='address' className='mt-2'>{t('adresse')}:</label>
                <input type='text' className='form-control mt-2' id="address" onBlur={formik.handleBlur} name='address' value={formik.values.address} onChange={formik.handleChange} />
                {formik.errors.address && formik.touched.address && <div className='alert alert-danger'>{formik.errors.address}</div>}

                {/* زر التحديث */}
                {isLoading ? (
                    <button className='btn bg-main btn-add-card mt-2' type="button">
                        <button className='btn bg-color btn-add-card '>  <i className="fas fa-spinner fa-spin"></i></button>
                    </button>
                ) : (
                    <button disabled={!(formik.isValid && formik.dirty)} className='btn btn-add-card w-100 border-radius p-8' type="submit">{t('UpdateProfile')}</button>
                )}

                {/* نص إشارة الموقع */}
                {/* <p className='mt-4'>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply</p> */}
            </form>
        </div>
    );
}
