import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { UserContext } from '../UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function DeleteAccount() {
    let { BasUrl3 , en} = useContext(UserContext)
    const { setUserToken, setUserData } = useContext(UserContext);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    async function handleDeleteSubmit(values) {
        try {
            const token = localStorage.getItem('UserToken');
            setIsLoading(true);

            const { data } = await axios.delete(
                `${BasUrl3}/profile/delete-account${en}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    data: values // قيم الحقول من النموذج
                }
            );

            if (data.status === true) {
                setIsLoading(false);
                setUserToken(data.data.token);
                setUserData(data);
                setUpdateSuccess(true);
                navigate('/Login');
                localStorage.removeItem('UserToken')
            }
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred');
            }
        }
    }

    let validationSchema = yup.object({
        password: yup.string().required('Password is required')
    });

    let formik = useFormik({
        initialValues: {
            password: ''
        },
        validationSchema,
        onSubmit: handleDeleteSubmit
    });

    return (
        <div className='w-50 mx-auto py-5'>
            {updateSuccess && (
                <div className='alert alert-success'>{t('deletedaccount')}    </div>
            )}

            {error && <div className='alert alert-danger'>{error}</div>}

            <form onSubmit={formik.handleSubmit}>
                <h5 className='fw-bold text-center'>{t('eleteccount')}</h5>

                <label htmlFor='password' className='mt-2'>
                {t('password')}
                </label>
                <input
                    type='password'
                    className='form-control mt-2'
                    id='password'
                    onBlur={formik.handleBlur}
                    name='password'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                />
                {formik.errors.password && formik.touched.password && (
                    <div className='alert alert-danger'>{formik.errors.password}</div>
                )}

                {isLoading ? (
                    <button className='btn bg-main btn-add-card mt-2' type='button'>
                        <i className='fas fa-spinner fa-spin'></i>
                    </button>
                ) : (
                    <button
                        disabled={!(formik.isValid && formik.dirty)}
                        className='btn btn-add-card w-100 border-radius p-8'
                        type='submit'
                    >
                  {t('eleteccount')}
                    </button>
                )}
            </form>
        </div>
    );
}
