import React, { useState } from 'react';
import axios from 'axios';

const PaymentForm = () => {
  const [paymentData, setPaymentData] = useState({
    amount: 100.00, 
    currency: 'USD', 
  });

  const handlePayment = async () => {
    try {
      const response = await axios.post(
        'YOUR_PAYTABS_API_ENDPOINT',
        paymentData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer SBJNHDGHHJ-JHJGKRKZTL-GMMK6TJZNG',
          },
        }
      );
      console.log(response.data)
    } catch (error) {
      console.error('  الدفع:', error);
    }
  };
  return (
    <div>
      <h2>نموذج الدفع</h2>
      <button onClick={handlePayment}>إجراء الدفع</button>
    </div>
  );
};

export default PaymentForm;
