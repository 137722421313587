import React, { createContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import i18n, { changeLanguage } from '../../i18n';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

export const UserContext = createContext();

export default function UserContextProvider(props) {
  const [Token, setToken] = useState([]);
  const [languageLoaded, setLanguageLoaded] = useState(false);

  const BasUrl = 'https://dashboard.cmask.net/front-api';
  const BasUrl2 = 'https://dashboard.cmask.net';
  const BasUrl3 = 'https://dashboard.cmask.net/api/';
  const tokenfirebase = Token
  const token = localStorage.getItem('UserToken');
  const storedLanguage = localStorage.getItem('language');
  const initialLanguage = storedLanguage !== null ? Number(storedLanguage) : 0;
  const [language, setLanguage] = useState(initialLanguage);
  const [changelanguage, setChangeLanguage] = useState(
    initialLanguage === 0 ? '?lang=en' : '?lang=ar'
  );
  const [UserToken, setUserToken] = useState(null);
  const [UserData, setUserData] = useState(null);
  const [AhmedAs, setAhmedA] = useState(null);
  const [counterCart, setCounterCart] = useState(0);
  const [direction, setDirection] = useState(initialLanguage === 0 ? 'ltr' : 'rtl');
  function getCartIt() {
    return axios.get(`${BasUrl2}/api/cart/show-cart${changelanguage}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  const { isError, data, isLoading } = useQuery('cartItems', getCartIt);
  useEffect(() => {
    if (!isLoading && !isError && data) {
      setCounterCart(data?.data?.data.cartItems?.length || 0);
    }
  }, [isLoading, isError, data, changelanguage]);
  async function DeleteProduct(id) {
    try {
      const response = await axios.delete(`${BasUrl3}cart/${id}`, {
        headers: {
          Authorization: `Bearer ${UserToken}`
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  const fetchData = async (values) => {
    try {
      const response = await axios.post(`${BasUrl3}products/search`, {
        name: values.name
      });
      setAhmedA(response?.data?.data);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    setChangeLanguage(language === 0 ? '?lang=en' : '?lang=ar');
    setDirection(language === 0 ? 'ltr' : 'rtl');
  }, [language]);

  useEffect(() => {
    localStorage.setItem('language', language);
    setChangeLanguage(language === 0 ? '?lang=en' : '?lang=ar');
    setDirection(language === 0 ? 'ltr' : 'rtl');
  }, [language]);

  const handleLanguageChange = (event) => {
    const selectedLanguage = Number(event.target.value);
    setLanguage(selectedLanguage);
    setChangeLanguage(selectedLanguage === 0 ? '?lang=en' : '?lang=ar');
    setDirection(selectedLanguage === 0 ? 'ltr' : 'rtl');

    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${selectedLanguage === 0 ? '?lang=en' : '?lang=ar'}`;
    window.history.pushState({ path: newUrl }, '', newUrl);

    localStorage.setItem('language', selectedLanguage);

    changeLanguage(selectedLanguage === 0 ? 'en' : 'ar');
    window.location.reload()
  };

  useEffect(() => {
    setTimeout(() => {
      document.documentElement.dir = direction;
    }, 2);
  }, [direction]);

  useEffect(() => {
    // تحميل البيانات بعد تحديد اللغة
    if (languageLoaded) {
      // اجراء الطلبات وتحميل المحتوى
    }
  }, [languageLoaded]);

  // تحميل اللغة
  useEffect(() => {
    changeLanguage(initialLanguage === 0 ? 'en' : 'ar');
    setLanguageLoaded(true);
  }, []);

  return (
    <UserContext.Provider
      value={{
        AhmedAs,
        BasUrl3,
        counterCart,
        DeleteProduct,
        BasUrl2,
        UserToken,
        setUserToken,
        setUserData,
        UserData,
        BasUrl,
        fetchData,
        en: changelanguage,
        language,
        setLanguage,
        handleLanguageChange,
        direction,
        tokenfirebase
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
